import classes from "../LiveChat.module.scss";
import { format } from "date-fns";

const Message = ({ watched, messageText, messageDate, fromClient }) => {
  return (
    <div
      className={`${classes.message} ${
        fromClient ? classes["message-client"] : ""
      }`}
    >
      <p className={classes["message-text"]}>
        {messageText}
        {/* <img src={watched} alt="Watched icon" /> */}
      </p>
      <span>
        {messageDate ? format(new Date(messageDate), " d.MM.yyyy HH:mm ") : ""}
      </span>
    </div>
  );
};

export default Message;
