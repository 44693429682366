import React, {useContext} from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import { AppContext } from "../AppContext";

export default function FAQ_Button(props) {

	const intl = useIntl();
	const context = useContext(AppContext);
	const faq_messageId = context.selectedPassenger?.tourOperator =='TUI' ? 'header.FAQText_TUI' :'header.FAQText';

	return (
		<li className="menuMisc__item menuMisc__item--withText ">
			<Dialog
				open={props.IsModalOpen}
				onClose={()=>props.SetModalOpen(false)}>
				<div>
					<div className="pxModal__closeButton" onClick={()=>props.SetModalOpen(false)}></div>
					<div style={{ padding: '15px' }} dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: faq_messageId }) }} />
				</div>
			</Dialog>
			<div onClick={()=>props.SetModalOpen(true)}>
			<i className="menuMisc__icon icon help-filled" />
			<span className="menuMisc__text "><FormattedMessage id="header.FAQ" /></span>
			</div>
		</li>
	);
}
