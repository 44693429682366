import React, { useState, useContext } from "react";
import { Modal } from "@material-ui/core";
import { AppContext } from "../AppContext";
import { FormattedMessage } from "react-intl";
import classes from "../css/InfantRelationDialog.module.scss";
import InfantDialogContent from "./InfantDialogContent";

export default function InfantRelationDialog2() {
  const context = useContext(AppContext);
  const [errorMsg, setErrorMsg] = useState(false);
  const [infantId, setInfantId] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);

  const getPaxData = (direction) => {
    let relatedParents;
    let notRelatedParents;
    let notRelatedInfants;
    const infants = context?.flightData[direction]?.passengers.filter(
      (passenger) => passenger.isInfant
    );

    if (infants?.length !== 0) {
      relatedParents = context?.flightData[direction]?.passengers.filter(
        (parent) => parent.relatedInfant?.paxId !== infants?.extId
      );
      if (relatedParents?.length === 0) {
        notRelatedParents = context?.flightData[direction]?.passengers.filter(
          (parent) => parent.isAdult
        );
      }

      if (infants?.length === relatedParents?.length) {
      } else {
        notRelatedParents = context?.flightData[direction]?.passengers.filter(
          (parent) =>
            parent.relatedInfant?.paxId === infants?.extId && parent.isAdult
        );

        notRelatedInfants = infants.filter((infant) => {
          return context?.flightData[direction]?.passengers.every(
            (parent) => parent.relatedInfant?.paxId !== infant?.extId
          );
        });
      }
    }

    return [notRelatedParents, notRelatedInfants];
  };

  const [notRelatedParents, notRelatedInfants] = getPaxData(0);
  const [notRelatedParentsReturn, notRelatedInfantsReturn] = getPaxData(1);

  const sameInfantBothDirections = notRelatedInfantsReturn?.find((infant) => {
    if (notRelatedInfants)
      return infant.passengerKey === notRelatedInfants[0].passengerKey;
  });

  const submitHandler = (e) => {
    e.preventDefault();
    setErrorMsg(false);

    let infantRelationReturn;
    if (sameInfantBothDirections) {
      const [selectedParentNameReturn] = notRelatedParentsReturn?.filter(
        (parent) => parent.extId === +e.target[1].value
      );
      infantRelationReturn = {
        parentId: +e.target[1].value,
        infantId: sameInfantBothDirections?.extId,
        parentName: `${selectedParentNameReturn?.firstName} ${selectedParentNameReturn?.lastName}`,
      };

      context.API.WebServiceAction(
        "PUT",
        "reservation/AddInfantToParent",
        infantRelationReturn,
        null,
        () => {
          setErrorMsg(true);
        }
      );
    }

    if (notRelatedInfants && notRelatedInfants.length !== 0) {
      const [selectedParentName] = notRelatedParents?.filter(
        (parent) => parent.extId === +e.target[0].value
      );

      const infantRelation = {
        parentId: +e.target[0].value,
        infantId: infantId,
        parentName: `${selectedParentName?.firstName} ${selectedParentName?.lastName}`,
      };

      context.API.WebServiceAction(
        "PUT",
        "reservation/AddInfantToParent",
        infantRelation,
        () => {
          context.LoadPassengerData(() => {
            context.OnCurrentFlightChanged(0);
          });
        },
        () => {
          setErrorMsg(true);
        }
      );
    }

    if (
      notRelatedInfantsReturn &&
      notRelatedInfantsReturn.length !== 0 &&
      !sameInfantBothDirections
    ) {
      const [selectedParentName] = notRelatedParentsReturn?.filter(
        (parent) => parent.extId === +e.target[0].value
      );

      const infantRelation = {
        parentId: +e.target[0].value,
        infantId: infantId,
        parentName: `${selectedParentName?.firstName} ${selectedParentName?.lastName}`,
      };
      context.API.WebServiceAction(
        "PUT",
        "reservation/AddInfantToParent",
        infantRelation,
        () => {
          context.LoadPassengerData(() => {
            context.OnCurrentFlightChanged(0);
          });
        },
        () => {
          setErrorMsg(true);
        }
      );
    }
  };

  if (notRelatedInfants?.length === 0 && notRelatedInfantsReturn?.length === 0)
    return null;

  return (
    <>
      {(notRelatedInfants || notRelatedInfantsReturn) && (
        <Modal
          open
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div className={classes.container}>
            {errorMsg && (
              <h2>
                <FormattedMessage id="myBooking.InfantRelation.Error" />
              </h2>
            )}

            {!errorMsg && (notRelatedInfants || notRelatedInfantsReturn) && (
              <>
                <h2>
                  <FormattedMessage id="myBooking.InfantRelation.InfantRelated" />
                </h2>

                <form id="form" onSubmit={submitHandler}>
                  {notRelatedInfants && (
                    <InfantDialogContent
                      notRelatedInfants={notRelatedInfants}
                      sameInfantBothDirections={sameInfantBothDirections}
                      notRelatedParents={notRelatedParents}
                      parentChoiceHandler={(disabledState) =>
                        setIsDisabled(disabledState)
                      }
                      infantIdHandler={(id) => setInfantId(id)}
                      notRelatedParentsReturn={notRelatedParentsReturn}
                    />
                  )}
                  {!notRelatedInfants && notRelatedInfantsReturn && (
                    <InfantDialogContent
                      direction={1}
                      notRelatedInfants={notRelatedInfantsReturn}
                      notRelatedParents={notRelatedParentsReturn}
                      parentChoiceHandler={(disabledState) =>
                        setIsDisabled(disabledState)
                      }
                      infantIdHandler={(id) => setInfantId(id)}
                    />
                  )}

                  {!(notRelatedParents?.length === 0 && notRelatedInfants[0]) &&
                    !(
                      notRelatedParentsReturn?.length === 0 &&
                      notRelatedInfantsReturn[0]
                    ) && (
                      <button type="submit" disabled={isDisabled}>
                        <FormattedMessage id="myBooking.InfantRelation.Button" />
                      </button>
                    )}
                </form>
              </>
            )}
          </div>
        </Modal>
      )}
    </>
  );
}
