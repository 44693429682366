import React from 'react';
import { Link } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import './css/SplashScreen.scss'

export default function LoadingScreen(props) {


  return (<div>
    

    <div className="LoadingSplash">
      <div><img alt='Smartlynx Airlines logo' src="/static/smartlynx/smartlynx_Logo.png"/></div>
      <div style={{textAlign:'center'}}><CircularProgress size={60}/></div>
   
    </div>
    
  </div>);

}
