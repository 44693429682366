import React, { useContext, useState } from "react";
import PassengerList from "./Seating/PassengerList";
import BuyItem from "./Components/BuyItem";
import { AppContext } from "./AppContext";
import { FormattedMessage } from "react-intl";
import NotificationBar from "./Components/NotificationBar";
import Banner from "./Components/Banner/Banner";
import SpecialAssistanceDialog from "./Dialogs/SpecialAssistanceDialog";
import SpecialAssistance from "./Components/SpecialAssistance/SpecialAssistance";
import Notification from "./Components/Notification";

const Services = () => {
  const context = useContext(AppContext);
  const [AssistanceDialogOpen, SetAssistanceDialogOpen] = useState(false);
  const [assistanceDialogContent, setAssistanceDialogContent] = useState();
  const servicesData = context.productsData.filter(function (product) {
    //Check if passenger has available product for any flight

    return (
      context.flightData.some((f) =>
        f.passengers.some(
          (pax) =>
            pax.passengerKey == context.selectedPassenger.passengerKey &&
            pax.availableProducts.some((p) => p.productId == product.id)
        )
      ) &&
      (product.typeId === 5 || product.typeId === 8)
    );
  });

  let notif = GetNotification();

  function DrawCheckInSegment(flightData, direction) {
    if (flightData == null) return null;

    return (
      <div>
        {
          //Check if any passenger has special assistance
          flightData.passengers.some((pax) =>
            pax.availableProducts.some((ap) =>
              context.productsData.some(
                (prod) => prod.id == ap.productId && prod.typeId === 7
              )
            )
          ) && (
            <>
              <SpecialAssistance
                onClick={() => {
                  setAssistanceDialogContent(flightData);
                  SetAssistanceDialogOpen(true);
                }}
                direction={direction}
              />
            </>
          )
        }
      </div>
    );
  }

  return (
    <div className="page">
      <div className="page__content page__content--mobileFullWidth">
        <Notification location="Services" />
        <Notification location="Services" direction={1} />
        <div>
          <div className="columns columns--fullWidth-992">
            <div className="columns__item">
              <div>
                <PassengerList />
                <Banner location="ServicesLeft" />
              </div>
            </div>
            <div className="columns__item">
              {/* {
              //Check if any passenger has special assistance
              flightData.passengers.some(pax => pax.availableProducts.some(ap => context.productsData.some(prod => prod.id == ap.productId && prod.typeId === 7))) &&
              <p style={{lineHeight:'30px'}}><FormattedMessage id="specialAssistance.needAssistance" /> <a href="#" onClick={(e) => { e.preventDefault(); SetAssistanceDialogOpen(context?.flightData[1]); }}> <FormattedMessage id="specialAssistance.clickHere" /></a></p>
            } */}

              {AssistanceDialogOpen && (
                <SpecialAssistanceDialog
                  handleUpdated={() => {
                    context.LoadPassengerData();
                    SetAssistanceDialogOpen(null);
                  }}
                  flight={assistanceDialogContent}
                  handleClose={() => SetAssistanceDialogOpen(null)}
                ></SpecialAssistanceDialog>
              )}
              <div>
                <div className="servicesContent">
                  <NotificationBar
                    title={notif?.title}
                    description={notif?.description}
                    icon="success"
                  />

                  <div className="listContent">
                    <div>
                      {DrawCheckInSegment(context?.flightData[0], 0)}
                      {DrawCheckInSegment(context?.flightData[1], 1)}
                    </div>
                    <div>
                      {servicesData.map((row, index) => {
                        return <BuyItem key={index} item={row} />;
                      })}
                    </div>
                    {servicesData?.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        <i>
                          <FormattedMessage id="sections.ServiceNotAvailable" />
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function GetNotification() {
    let notificationMessage = [];
    context.flightData?.forEach((flt) => {
      flt.passengers.forEach((pax) => {
        pax.ssr.split(",").forEach((ssr) => {
          let prod = servicesData.find((p) => p.ssr == ssr && ssr);
          if (prod != null) {
            notificationMessage.push(
              <div key={pax.extId + ssr}>
                <b>
                  {pax.firstName} {pax.lastName}
                </b>{" "}
                {prod.translations.find((p) => p.key === "title")?.content}{" "}
                {flt.flightNumber}
              </div>
            );
          }
        });
      });
    });
    if (notificationMessage?.length > 0)
      return {
        title: <FormattedMessage id="payment.YouAlreadyFromThisList" />,
        description: notificationMessage,
      };
    else return null;
  }
};
export default Services;
