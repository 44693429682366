import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import PayseraLogo from "../images/paysera.png";
import paypalLogo from "../images/Paypal.png";
import VisaLogo from "../images/visa_secure.png";
import { AppContext } from "../AppContext";

export default function Footer(props) {
  const intl = useIntl();
  const context = useContext(AppContext);
 
  return (
    <div className="footer">
      <div className="container">
        <div>
          <div className="row footer-logos">
            <div className="col-xs-6 col-sm-4">
              <div className="secure-payment clearfix">
                <img alt="Paysera logo" src={PayseraLogo}></img>
                {context.PaymentOptions.includes("PayPal") && (
                  <img
                    alt="Paysera logo"
                    style={{ marginLeft: "15px" }}
                    src={paypalLogo}
                  ></img>
                )}
                <img
                  alt="Visa logo"
                  style={{ marginLeft: "15px" }}
                  src={VisaLogo}
                ></img>
              </div>
            </div>
          </div>
          <div className="row cookie-disclaimer">
            We use cookies to ensure that we give you the best experience on our
            website
          </div>
          <div className="row cookie-disclaimer">
            SmartLynx Airlines SIA, Registration nr.: 40003056133, +371
            60003089, info@smartlynx.aero, Mazrudas, Marupe municipality,
            LV-2167, Latvia&nbsp;|&nbsp;
            <div style={{ display: "inline" }} />
            <a href={intl.formatMessage({ id: "footer.TermsConditionsURL" })}>
              {intl.formatMessage({ id: "footer.TermsConditions" })}
            </a>
            &nbsp;|&nbsp;
            <a href={intl.formatMessage({ id: "footer.PrivacyPolicyURL" })}>
              {intl.formatMessage({ id: "footer.PrivacyPolicy" })}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
