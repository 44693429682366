
import React from 'react';
import clsx from 'clsx';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import {FormattedDisplayName} from "react-intl";
import { AppContext } from "./../AppContext";

class LangSelector extends React.Component {
  
    onLangChange=(lang)=>
    {
      this.context.LangChange(lang);
      this.setState({IsMenuActive: false});
    }

    handleClickAway = () => {
      if (this.state.IsMenuActive)
      { 
          this.setState({IsMenuActive: false});
      }
    }
    render() {
      return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <li onMouseUp={this.handleClick} className="menuMisc__item menuMisc__item--withText menuMisc__item--languageButton  ">
            <i className="menuMisc__flagIcon flagIcon" style={{backgroundImage: 'url("/images/flags/'+this.context.language+'.svg")'}} />
            <span className="menuMisc__text menuMisc__text--leftPadding">{this.context.languagesAvailable.find(l=>l.code===this.context.language)?.title}</span>
            <i className="menuMisc__icon menuMisc__icon--down icon back2" />

            <ul className={clsx("languageList ", this.state.IsMenuActive && "languageList--active")}>
            {
              this.context.languagesAvailable.map((lang,indx)=>
              (
                <li key={indx} className={clsx("languageList__language", lang.code===this.context.language && "languageList__language--current")} onClick={()=>this.onLangChange(lang.code)}>
                  <i className="flagIcon" style={{backgroundImage: 'url("/images/flags/'+lang.code+'.svg")'}} />
                  <span className="languageList__languageName">{lang.title}</span>
                </li>
              ))
            }
            </ul>
        </li>
        </ClickAwayListener>
);
      
}

 handleClick(e) {
    e.preventDefault();
    this.setState({IsMenuActive: true});
  }

constructor(props) {
    super(props);
    this.state = {
      IsMenuActive: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

}
LangSelector.contextType = AppContext;

export default LangSelector;