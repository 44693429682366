import React, { useContext } from "react";
import PassengerList from "./Seating/PassengerList";
import BuyItem from "./Components/BuyItem";
import { AppContext } from "./AppContext";
import { FormattedMessage } from "react-intl";
import Banner from "./Components/Banner/Banner";
import Notification from "./Components/Notification";

const Meals = () => {
  const context = useContext(AppContext);
  const servicesData = context.productsData.filter(function (product) {
    //Check if passenger has available product for any flight
    return (
      context.flightData.some((f) =>
        f.passengers.some(
          (pax) =>
            pax.passengerKey == context.selectedPassenger.passengerKey &&
            pax.availableProducts.some((p) => p.productId == product.id)
        )
      ) && product.typeId === 4
    );
  });

  return (
    <div className="page">
      <div className="page__content page__content--mobileFullWidth">
        <Notification location="Meals" />
        <Notification location="Meals" direction={1} />
        <div>
          <div className="columns columns--fullWidth-992">
            <div className="columns__item">
              <div>
                <PassengerList />
                <Banner location="MealsLeft" />
              </div>
            </div>
            <div className="columns__item">
              <div>
                <div className="servicesContent">
                  <div className="listContent">
                    <div>
                      {servicesData.map((row, index) => (
                        <BuyItem key={index} item={row} />
                      ))}
                    </div>
                    {servicesData?.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        <i>
                          <FormattedMessage id="sections.MealsNotAvailable" />
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Meals;
