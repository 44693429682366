import React, { useEffect, useContext, useReducer, useState } from "react";
import SeatPlan from "./Seating/SeatPlan";
import PassengerList from "./Seating/PassengerList";
import SeatInfo from "./Seating/SeatInfo";
import FlightSegment from "./Components/FlightSegment";
import NotificationBar from "./Components/NotificationBar";
import { FormattedMessage } from "react-intl";
import { AppContext } from "./AppContext";
import clsx from "clsx";
import InfantRelationDialog from "./Dialogs/InfantRelationDialog";
import Notification from "./Components/Notification";

export default function Seating() {
  const context = useContext(AppContext);

  let nofitication;

  let NotAvailableToBuy =
    context.productsData.length == 0 ||
    context?.productsData
      .filter((p) => p.type == "Seat")
      .some((seat) => {
        return context.selectedPassenger?.availableProducts.some((product) => {
          if (product.productId === seat.id) {
            return true;
          } else {
            return false;
          }
        });
      });

  if (context.selectedFlight?.isDeparted)
    nofitication = {
      title: <FormattedMessage id="common.FlightDeparted" />,
      icon: "departed",
    };
  else if (context.selectedPassenger?.checkedIn)
    nofitication = {
      title: <FormattedMessage id="seating.PaxAlreadyCheckedIn" />,
      icon: "success",
    };
  else if (context.selectedPassenger?.requestedSeat)
    nofitication = {
      title: <FormattedMessage id="seating.PaxHaveAllocatedSeat" />,
      icon: "success",
    };

  return (
    <>
      <InfantRelationDialog />
      <div className="page__content page__content--mobileFullWidth">
        <Notification location={"Seating"} />

        <FlightSegment />
        <div>
          <div className="columns columns--fullWidth-768">
            <div className="columns__item">
              <div>
                <PassengerList />
                <SeatInfo />
              </div>
            </div>
            <div className="columns__item">
              <div style={{ textAlign: "center" }}>
                <div className="pageCloud">
                  <NotificationBar
                    title={nofitication?.title}
                    icon={nofitication?.icon}
                  />
                  <div>
                    {NotAvailableToBuy && <SeatPlan />}
                    {!NotAvailableToBuy && (
                      <h3 style={{ color: "grey" }}>
                        {<FormattedMessage id="seating.NotAvailableToBuy" />}
                      </h3>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
