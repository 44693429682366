import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router";
import { format } from "date-fns";
import { AppContext } from "./AppContext";
import { FormattedMessage } from "react-intl";
import CheckInDialog from "./Dialogs/CheckInDialog";
import Notification from "./Components/Notification";
import PaymentAcceptedDialog from "./Dialogs/PaymentAccepted";
import InfoIcon from "@material-ui/icons/Info";

export default function MyBooking(props) {
  const context = useContext(AppContext);
  const history = useHistory();
  const [PaySuccessDialogOpen, SetSuccessDialogOpen] = useState(false);
  const [CheckinForFlight, SetCheckinFlight] = useState(null);
  const [confirmAsk, setConfirmAsk] = useState(false);

  function AcceptPaymentConfirm() {
    const urlParams = new URLSearchParams(window.location.search);
    let paymentSuccess = urlParams.get("paymentSuccess");

    if (paymentSuccess != null) {
      SetSuccessDialogOpen(true);
      history.push("/mybooking");
    }
  }

  function CheckinClick(flightData) {
    SetCheckinFlight(flightData);
  }

  function IsCheckinAvaliableForFlight(flightData) {
    if (flightData == null) return false;
    //TODO: Check products available
    return flightData.passengers.some(
      (p) => !p.checkedIn && p.availableProducts.some((r) => r.productId === 34)
    ); // CHKIN
  }

  useEffect(() => {
    AcceptPaymentConfirm();
  }, []);

  // <div className={clsx("mobileMenu__overlay", this.state.IsMobileMenuActive && "mobileMenu__overlay--active")}/>

  function DrawTransactions(transactions) {
    if (!transactions?.length > 0) return null;
    return (
      <div className="groupedOrdersList">
        <div className="groupedOrdersList__title">
          <FormattedMessage id="payment.transactions" />
        </div>
        <div className="groupedOrdersList__list">
          {transactions?.map((tr, id) => {
            return (
              <div key={id} className="groupedOrdersItem">
                <div className="groupedOrdersItem__leftSide">
                  <div className="groupedOrdersItem__date">
                    <div className="pxDate">
                      {format(new Date(tr.date), "dd/MM/yy")}
                      {!tr.paid && (
                        <div
                          style={{
                            marginLeft: "15px",
                            color: "red",
                            display: "inline",
                          }}
                        >
                          <FormattedMessage id="payment.notpaid" />
                        </div>
                      )}{" "}
                    </div>
                    <div className="groupedOrdersItem__segmentInfo">
                      <FormattedMessage id="payment.order" />: {tr.orderId}
                    </div>
                  </div>
                  <div className="groupedOrdersItem__ordersByGroups" />
                </div>
                <div className="groupedOrdersItem__rightSide">
                  {/* Section displaying the total price */}
                  <div className="groupedOrdersItem__total">
                    <span>
                      {tr.totalPrice?.toFixed(2)}
                      {tr.currency}
                    </span>
                  </div>

                  {/* Conditional section for unpaid transactions */}
                  {!tr.paid && (
                    <>
                      {confirmAsk ? (
                        <>
                          <h2>
                            <FormattedMessage id="payment.sure" />
                          </h2>
                          <div
                            onClick={() =>
                              context.API.WebServiceAction(
                                "GET",
                                `transactions/cancel/${tr.transactionId}`,
                                null,
                                context.LoadPassengerData()
                              )
                            }
                            className="groupedOrdersItem__moreInfo"
                          >
                            <FormattedMessage id="payment.yes" />
                          </div>
                          <div
                            onClick={() => setConfirmAsk(false)}
                            className="groupedOrdersItem__moreInfo groupedOrdersItem__moreInfo_cancel"
                          >
                            <FormattedMessage id="payment.no" />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            onClick={() =>
                              window.location.replace(
                                `${process.env.REACT_APP_WS_URL}/Transactions/pay/${tr.transactionId}`
                              )
                            }
                            className="groupedOrdersItem__moreInfo"
                          >
                            <FormattedMessage id="payment.pay" />
                          </div>
                          <div
                            onClick={() => setConfirmAsk(true)}
                            className="groupedOrdersItem__moreInfo groupedOrdersItem__moreInfo_cancel"
                          >
                            <FormattedMessage id="payment.cancel" />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  {/* Condition for paid transactions */}
                  {tr.paid && (
                    <a
                      style={{ backgroundColor: "unset", marginLeft: "15px" }}
                      href={`/Receipt?t=${tr.transactionId}`}
                      target="_blank"
                    >
                      <div className="groupedOrdersItem__moreInfo">
                        <FormattedMessage id="myBooking.receipt" />
                      </div> 
                    </a>
                  )}
                </div>
                {!tr.paid && context.PaymentOptions.includes("PayPal") && (
                  <div
                    className="groupedOrdersItem__bottom"
                  >
                    <InfoIcon
                      style={{ marginRight: "5px", fontSize: "medium" }}
                    />
                    <FormattedMessage
                      id="payment.cancelDesc" /> 
                  </div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  function DrawPaidProducts(transactions, context) {
    var paidTransactions = transactions?.filter((t) => t.paid);
    if (!paidTransactions?.length > 0) return null;
    var allPaidProducts = [].concat.apply(
      [],
      paidTransactions.map((t) => t.products)
    );
    return (
      <div className="currentOrdersList">
        <header className="currentOrdersList__header">
          <div className="currentOrdersList__title">
            <FormattedMessage id="payment.purchases" />
          </div>
          {/* <div className="currentOrdersList__reprintButton">
      Распечатать подтверждение
    </div> */}
        </header>
        <div className="currentOrdersList__ordersFlexWrapper">
          {DrawPaidProductForFlight(
            0,
            context?.flightData[0],
            allPaidProducts.filter(
              (t) => t.flightId == context?.flightData[0]?.id
            ),
            context?.productsData
          )}
          {DrawPaidProductForFlight(
            1,
            context?.flightData[1],
            allPaidProducts.filter(
              (t) => t.flightId == context?.flightData[1]?.id
            ),
            context?.productsData
          )}
        </div>
      </div>
    );
  }

  function DrawPaidProductForFlight(
    direction,
    flightData,
    paidProducts,
    productCatalogue
  ) {
    if (!paidProducts?.length > 0) return null;

    return (
      <section>
        <header className="currentOrdersList__listHeader">
          {direction == 0 ? (
            <FormattedMessage id="common.Outbound" />
          ) : (
            <FormattedMessage id="common.Inbound" />
          )}
        </header>
        <div className="currentOrdersList__list">
          {paidProducts?.map((product, id) => {
            var passenger = flightData.passengers.find(
              (p) =>
                (p.extId == product.passengerExtId && p.extId != null) ||
                (p.id == product.passengerId && p.id != null)
            );
            var productdef = productCatalogue.find(
              (p) => p.id == product.productId
            );
            // Заменить разделитель
            let variant = product?.variant?.includes("~=")
              ? product?.variant?.replace("~=", " ")
              : product?.variant;
            return (
              <div key={id} className="currentOrdersList__item">
                <span className="currentOrdersList__orderName">
                  {
                    productdef?.translations?.find(
                      (item) => item.key == "title"
                    )?.content
                  }
                </span>

                {product.variant && (
                  // <span className="currentOrdersList__orderSeatNumber">
                  //   {" "}
                  //   - {product.variant}
                  // </span>
                  <div
                    style={{
                      display: "block",
                      maxWidth: "190px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                    className="currentOrdersList__orderSeatNumber"
                  >
                    {" "}
                    {variant}
                  </div>
                )}
                <div>
                  <div className="currentOrdersList__segmentInfo">
                    {passenger?.firstName} {passenger?.lastName}
                  </div>
                  <div className="currentOrdersList__segmentInfo">
                    <FormattedMessage id="common.Flight" />:{" "}
                    {flightData.flightNumber}
                  </div>
                  <div className="currentOrdersList__segmentInfo">
                    <FormattedMessage id="payment.order" />: {product.orderId}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </section>
    );
  }

  function DrawSection(flightData, direction) {
    if (flightData == null) return null;
    return (
      <div className="segmentsSection__segment ">
        <div className="segmentsSection__segmentContent">
          <div className="segmentsSection__header">
            <div className="segmentsSection__departure segmentsSection__departure--outbound">
              <span>
                {direction == 0 ? (
                  <FormattedMessage id="common.Outbound" />
                ) : (
                  <FormattedMessage id="common.Inbound" />
                )}
              </span>
              ,{" "}
            </div>
            <div className="segmentsSection__flightNumber">
              {flightData.flightNumber}
            </div>
            <div className="segmentsSection__date pxDate">
              {!!flightData.etd
                ? format(new Date(flightData.etd), "dd/MM/yy")
                : null}
            </div>
          </div>
          <div className="segmentsSection__infoBlock">
            <div className="segmentsSection__info">
              <div className="segmentsSection__flightInfo">
                <div className="segmentsSection__flightInfoTitle">
                  <FormattedMessage id="common.Departure" />
                </div>
                <div className="segmentsSection__flightInfoAirports">
                  {flightData.depAirportName}
                </div>
              </div>
              <div className="segmentsSection__flightInfo arrival">
                <div className="segmentsSection__flightInfoTitle ">
                  <FormattedMessage id="common.Arrival" />
                </div>
                <div className="segmentsSection__flightInfoAirports">
                  {flightData.arrAirportName}
                </div>
              </div>
              <div className="segmentsSection__passengerInfo">
                {flightData.passengers?.map((person, id) => {
                  return (
                    <div key={id} className="segmentsSection__passenger">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.621"
                        height="19.738"
                        viewBox="0 0 16.621 19.738"
                      >
                        <g transform="translate(-1.559)">
                          <path
                            className="a"
                            d="M18.18,19.738h-2A6.223,6.223,0,0,0,9.869,13.62a6.223,6.223,0,0,0-6.31,6.118h-2A8.225,8.225,0,0,1,9.87,11.62,8.225,8.225,0,0,1,18.18,19.738Z"
                          />
                          <path
                            className="a"
                            d="M9.87,10.97a5.485,5.485,0,1,1,5.486-5.485A5.492,5.492,0,0,1,9.87,10.97ZM9.87,2a3.485,3.485,0,1,0,3.486,3.485A3.488,3.488,0,0,0,9.87,2Z"
                          />
                        </g>
                      </svg>
                      {person.firstName} {person.lastName}{" "}
                      {person.requestedSeat}{" "}
                      <div
                        style={{
                          fontWeight: "normal",
                          display: "flex",
                          float: "right",
                        }}
                      >
                        {" "}
                        {person.ssr}
                      </div>
                      {person.checkedIn && (
                        <a
                          style={{
                            backgroundColor: "unset",
                            marginLeft: "15px",
                          }}
                          href={
                            "/BoardingPass?f=" +
                            flightData.id +
                            "&p=" +
                            person.extId
                          }
                          className="checkIn__btn checkIn__btn--download"
                          target="_blank"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            {IsCheckinAvaliableForFlight(flightData) && (
              <div
                style={{ backgroundColor: "#F3F3F3" }}
                className="seatingMessage seatingMessage--type-close"
              >
                <div className="seatingMessage__icon" />
                <div className="seatingMessage__content">
                  <div
                    style={{
                      color: "#1C125F",
                      marginTop: "15px",
                      fontSize: "16px",
                    }}
                    className="seatingMessage__title"
                  >
                    <FormattedMessage id="myBooking.rememberToCheckin" />
                  </div>
                  <div className="seatingMessage__message">
                    <div />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  function DrawCheckInSegment(flightData, direction) {
    if (flightData == null) return null;

    return (
      <div>
        <div className="checkIn__segment">
          {flightData?.isDeparted && (
            <div className="checkIn__status checkIn__status--done ">
              <FormattedMessage id="common.FlightDeparted" />
            </div>
          )}

          <div className="checkIn__segmentData">
            <div className="checkIn__field checkIn__field--date">
              <div className="checkIn__fieldTitle status">
                {direction == 0 ? (
                  <FormattedMessage id="common.Outbound" />
                ) : (
                  <FormattedMessage id="common.Inbound" />
                )}
              </div>
              <div className="checkIn__fieldValue statusValue">
                {!!flightData?.etd
                  ? format(new Date(flightData?.etd), "dd/MM/yy")
                  : null}
              </div>
            </div>
            <div className="checkIn__field checkIn__field--airport">
              <div className="checkIn__fieldTitle departure">
                <FormattedMessage id="common.Departure" />
              </div>
              <div className="checkIn__fieldValue departureValue">
                <div className="pxTime pxTime--inline">
                  {!!flightData?.etd
                    ? format(new Date(flightData?.etd), "dd/MM/yy")
                    : null}{" "}
                  /
                </div>
                {flightData?.depAirportName}
              </div>
            </div>
            <div className="checkIn__field checkIn__field--airport">
              <div className="checkIn__fieldTitle arrival">
                <FormattedMessage id="common.Arrival" />
              </div>
              <div className="checkIn__fieldValue arrivalValue">
                <div className="pxTime pxTime--inline">
                  {!!flightData?.eta
                    ? format(new Date(flightData?.eta), "hh:mm")
                    : null}{" "}
                  /
                </div>
                {flightData?.arrAirportName}
              </div>
            </div>
            <div className="checkIn__field checkIn__field--flightNumber">
              <div className="checkIn__fieldTitle flightNumber">
                <FormattedMessage id="common.FlightNumber" />
              </div>
              <div className="checkIn__fieldValue flightNumberValue">
                {flightData?.flightNumber}
              </div>
            </div>
          </div>
          <div className="checkIn__field checkIn__field--button">
            <div className="checkIn__fieldValue">
              {IsCheckinAvaliableForFlight(flightData) && (
                <button
                  onClick={() => CheckinClick(flightData)}
                  className="checkIn__btn checkIn__btn--checkin"
                >
                  Check-in
                </button>
              )}
              <a className="checkIn__btn checkIn__btn--mail checkIn__btn--hide"></a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="page page--noPadding">
      <Notification location="MyBooking" />
      <Notification location="MyBooking" direction={1} />
      {PaySuccessDialogOpen && (
        <PaymentAcceptedDialog
          handleClose={() => SetSuccessDialogOpen(false)}
        />
      )}

      <CheckInDialog
        flight={CheckinForFlight}
        onClose={() => SetCheckinFlight(null)}
        onSuccess={() => {
          context.LoadPassengerData(() => {
            context.SetDataLoading(false);
            SetCheckinFlight(null);
          });
        }}
      ></CheckInDialog>
      <div className="pageHeader ">
        <div>
          <FormattedMessage id="header.MyBooking" /> {context?.bookingNumber}
        </div>
      </div>
      <div className="pageSubHeader">
        <div>
          <FormattedMessage id="myBooking.WelcomeAndCheck" />
        </div>
      </div>
      <div className="segmentsSection segmentsSection--size-small">
        <div className="segmentsSection__segmentFlexWrapper">
          {DrawSection(context?.flightData[0], 0)}
          {DrawSection(context?.flightData[1], 1)}
        </div>
      </div>
      <div className="checkIn ">
        <div className="page">
          <div>
            <FormattedMessage id="myBooking.PrintBoardingPass" />
          </div>
          <div className="checkIn__segments">
            {DrawCheckInSegment(context?.flightData[0], 0)}
            {DrawCheckInSegment(context?.flightData[1], 1)}
          </div>
          {DrawTransactions(context?.transactions)}
          {DrawPaidProducts(context?.transactions, context)}
        </div>
      </div>
      <div className="myBookingFabMessages" />
    </div>
  );
}
