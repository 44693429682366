import React from "react";
import { FormattedMessage } from "react-intl";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core/";

const NotAllowedAddInCartDialog = ({ closeModal }) => {
  return (
    <Dialog open onClose={closeModal} maxWidth="sm" fullWidth={true}>
      <DialogContent style={{ textAlign: "center" }}>
        <h3>
          <FormattedMessage id="payment.notAllowed" />
        </h3>
      </DialogContent>
      {/* <div
    className="pxModal__closeButton"
    onClick={() => this.setState({ UnpaidCartDialogOpen: false })}
  /> */}

      <DialogActions style={{ justifyContent: "center", padding: "20px" }}>
        <div className="AddingToBasket__button  " onClick={closeModal}>
          <div>
            <span>
              <span>Close</span>
            </span>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default NotAllowedAddInCartDialog;
