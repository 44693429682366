import React, { useState, useEffect,useContext } from 'react';
import './css/boardingPass.css'
import Logo from './images/logo.png'
import bwipjs from 'bwip-js';
import { AppContext } from "./AppContext";

function BoardingPass(props) {
  const context = useContext(AppContext);
  const [data, setData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const ReadData = () => {


    //User option
    if (params.get('f') != null && params.get('p')) {
      context.API.WebServiceJSON('GET', 'checkin/boardingpass/' + params.get('f') + '/' + params.get('p'), null, (data) => {setData(data); drawQrCode(data?.qrCode)}, null);
    }
    // Web service option
    else if (params.get('data') != null)
    {
      var decodedStringData = decodeURIComponent(params.get('data'));
      var jsonData = JSON.parse(decodedStringData)
      setData(jsonData);
    }
  }

  function drawQrCode(text) {
    if (text == null) return;

    bwipjs.toCanvas('QR_PDF417', {
      bcid: 'pdf417',       // Barcode type
      text: text,    // Text to encode
      scale: 3,               // 3x scaling factor
      //height:      10,              // Bar height, in millimeters
      includetext: false,            // Show human-readable text
      textxalign: 'center',        // Always good to set this
    });

  }


  useEffect(ReadData, []);
  useEffect(() => {
    drawQrCode(data?.qrCode)
  });

  if (data == null) { return null } else
    return (
      <div className="boardingPass">
        <div calss="top">
          <table width="100%" border={0}>
            <tbody>
              <tr>
                <td width="10%">
                  <div className="top_logo">
                    <img alt='Smartlynx Airlines logo' src={Logo} />
                  </div>
                </td>
                <td width="40%">
                  <div className="top_text">
                    S<span className="Caps">mart</span>L
                    <span className="Caps">ynx</span>
                    <br />
                    BOARDING PASS
                  </div>
                </td>
                <td width="50%">
                  <canvas className="top_qr" id="QR_PDF417"></canvas>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="main">
          <div>
            <table width="100%" border={0}>
              <tbody>
                <tr>
                  <td colSpan={4}> <h1>{data.name}
                  {data.priority && <span style={{float:'right'}}>☆ PRIORITY BOARDING ☆</span>}
                  </h1>                  
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <hr />
                  </td>
                </tr>
                <tr align="left">
                  <th width="24%">Flight</th>
                  <th width="28%">From</th>
                  <th width="28%">To</th>
                  <th width="20%">Date</th>
                </tr>
                <tr>
                  <td style={{ verticalAlign: "top" }}>
                    <span className="Flight">{data?.flightNumber}</span>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <span className="FromTo">{data?.depAirportCode}</span>
                    <br />
                    <span className="FromTo_small">{data?.depAirportName}</span>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <span className="FromTo">{data?.arrAirportCode}</span>
                    <br />
                    <span className="FromTo_small">{data?.arrAirportName}</span>
                  </td>
                  <td style={{ verticalAlign: "top" }}>
                    <span className="Flight">{data?.date}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={4}>
                    <hr />
                  </td>
                </tr>
                <tr align="left">
                  <th>Booking reference</th>
                  <th colSpan={2}>Seq. number</th>
                  <td rowSpan={5}>
                    <div className="seat_box">
                      Seat
                      <br />
                      <span className="bold">{(data?.seat)? data?.seat: "-"}</span>
                      <br />
                      Boarding time
                      <br />
                      <span className="bold">{data?.boardingTime}</span>
                      <br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className="FromTo">{data?.booking}</span>
                  </td>
                  <td colSpan={2}>
                    <span className="FromTo">{data?.seq}</span>
                  </td>
                </tr>
                <tr align="left">
                  <th colSpan={3}>Gate</th>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <span className="Gate" style={{ color:data.goToCheckinDesk? "red":"" }}>{data.goToCheckinDesk ? "Document check is mandatory. Please contact Check-in desk and gate staff!" : "Please check monitors"}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <p>
              &gt;&gt; &gt;&gt;&nbsp;&nbsp;Your vacation starts with us. Welcome on
              board!&nbsp;&nbsp;&lt;&lt; &lt;&lt;
            </p>
          </div>
        </div>
      </div>
    )
}
export default BoardingPass;