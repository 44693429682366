import React from 'react';
import clsx from 'clsx';
import { FormattedMessage } from "react-intl";


export default function Ribbon(props) {

	return (
		<div className="topLevelContainer clearfix">
		<div className="layoutMenu layoutMenu--wizardMenu layoutMenu--withoutImage" >

			<div className={clsx("layoutMenu__item", props.activeSection === 1 && "layoutMenu__item--isActive")}>
			{/* style={{ cursor: 'pointer' }} */}
				<span className="left-arrow" />
				<div className="layoutMenu__itemContent" onClick={() => props.OnSelectedSectionChange(1)}>
					<i className="layoutMenu__icon icon chair" />
					<div className="layoutMenu__title"><FormattedMessage id="sections.СhooseSeating" /></div>
					<div className="layoutMenu__wizardIcon layoutMenu__wizardIcon--isActive layoutMenu__wizardIcon--type-NONE ">1</div>
				</div>
				<span className="right-arrow" />

			</div>

			<div className={clsx("layoutMenu__item", props.activeSection === 2 && "layoutMenu__item--isActive")} >
				<span className="left-arrow" />
				<div className="layoutMenu__itemContent" onClick={() =>{}}>
					<i className="layoutMenu__icon icon baggage" />
					<div className="layoutMenu__title"><FormattedMessage id="sections.ExtraLuggage" /></div>
					<div className="layoutMenu__wizardIcon  layoutMenu__wizardIcon--type-NONE ">2</div>
				</div>
				<span className="right-arrow" />
			</div>
			<div className={clsx("layoutMenu__item", props.activeSection === 3 && "layoutMenu__item--isActive")}>
				<span className="left-arrow" />
				<div className="layoutMenu__itemContent" onClick={() => {}}>
					<i className="layoutMenu__icon icon flight_extras" />
					<div className="layoutMenu__title"><FormattedMessage id="sections.Meals" /></div>
					<div className="layoutMenu__wizardIcon  layoutMenu__wizardIcon--type-NONE ">3</div>
				</div>
				<span className="right-arrow" />
			</div>
			<div className={clsx("layoutMenu__item", props.activeSection === 4 && "layoutMenu__item--isActive")}>
				<span className="left-arrow" />
				<div className="layoutMenu__itemContent" onClick={() => {}}>
					<i className="layoutMenu__icon icon airportServices" />
					<div className="layoutMenu__title"><FormattedMessage id="sections.Services" /></div>
					<div className="layoutMenu__wizardIcon  layoutMenu__wizardIcon--type-NONE ">4</div>
				</div>
				<span className="right-arrow" />
			</div>
			<div className={clsx("layoutMenu__item", props.activeSection < 5 && "layoutMenu__item--disabled", props.activeSection === 5 && "layoutMenu__item--isActive")} onClick={() => {}}>
				<span className="left-arrow" />
				<div className="layoutMenu__itemContent">
					<i className="layoutMenu__icon icon cart" />
					<div className="layoutMenu__title"><FormattedMessage id="sections.Complete" /></div>
					<div className="layoutMenu__wizardIcon  layoutMenu__wizardIcon--type-NONE ">5</div>
				</div>
				<span className="right-arrow" />
			</div>
		</div>
	</div>
	);
}
