import React from "react";
import { FormattedMessage } from "react-intl";
import { AppContext } from "./../AppContext";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { withRouter } from "react-router";

class SectionBasket extends React.Component {
  render() {
    const handleBasketTooltipToggle = () => {
      if (itemsInBasket > 0) {
        this.setState({ IsCartPopupActive: !this.state.IsCartPopupActive });
      }
    };

    const handleClickAway = () => {
      if (this.state.IsCartPopupActive) {
        this.setState({ IsCartPopupActive: false });
      }
    };

    const priceTotal = this.context.basketData.reduce(
      (total, item) => total + item?.price,
      0
    );
    const itemsInBasket = this.context.basketData.length;

    const Next = () => {
      if (this.props.activeSection < 5) {
        this.props.OnSelectedSectionChange(this.props.activeSection + 1);
      }
    };
    const Prev = () => {
      if (this.props.activeSection > 1) {
        this.props.OnSelectedSectionChange(this.props.activeSection - 1);
      }
    };

    let sectionName;

    switch (this.props.activeSection) {
      case 1:
        sectionName = <FormattedMessage id="sections.СhooseSeating" />;
        break;
      case 2:
        sectionName = <FormattedMessage id="sections.ExtraLuggage" />;
        break;
      case 3:
        sectionName = <FormattedMessage id="sections.Meals" />;
        break;
      case 4:
        sectionName = <FormattedMessage id="sections.Services" />;
        break;
      case 5:
        sectionName = <FormattedMessage id="sections.Complete" />;
        break;
      case 6:
        sectionName = <FormattedMessage id="header.MyBooking" />;
        break;
    }

    if (this.props.activeSection > 4) return ""; // hide
    else
      return (
        <div>
          <div className="wizardHeader wizardHeader--wizardEnabled ">
            <div className="wizardHeader__topRow">
              <div
                style={{ float: "left" }}
                className={
                  this.props.activeSection == 1
                    ? "wizardHeader__prevButton--hidden"
                    : "wizardHeader__prevButton"
                }
                onClick={Prev}
              >
                <span>
                  <FormattedMessage id="common.Back" />
                </span>
              </div>
              <div className="wizardHeader__stepIndex">
                <span>{this.props.activeSection}</span>
              </div>
              <div className="pageHeader wizardHeader__headerTitle">
                <div>{sectionName}</div>
              </div>
              <div
                className={
                  "wizardHeader__nextButton " +
                  (this.props.activeSection > 4
                    ? "wizardHeader__nextButton--hidden"
                    : "")
                }
                onClick={Next}
              >
                <span>
                  <FormattedMessage id="common.Next" />
                </span>
              </div>
              <div
                className={
                  "wizardHeader__cart " +
                  (this.props.activeSection > 4
                    ? "wizardHeader__cart--hidden"
                    : "")
                }
              >
                <ClickAwayListener onClickAway={handleClickAway}>
                  <div className="basketTooltipButton undefined">
                    <div>
                      <div
                        className="wizardCartButton"
                        onClick={handleBasketTooltipToggle}
                      >
                        <div
                          className="wizardCartButton__link ext-cart-link"
                          style={{ cursor: "pointer" }}
                        />
                        <span className="wizardCartButton__cartIcon" />
                        <span>{priceTotal?.toFixed(2)} EUR</span>
                      </div>
                    </div>
                    <div
                      className={
                        this.state.IsCartPopupActive
                          ? "basketTooltipButton__tooltipRoot"
                          : "basketTooltipButton__tooltipRoot basketTooltipButton__tooltipRoot--hidden"
                      }
                    >
                      <div>
                        <div className="basketDropdown">
                          <div className="basketDropdown__servicesList">
                            {this.context.basketData && (
                              <div className="basketDropdown__serviceGroup">
                                <table className="basketDropdown__serviceGroupList">
                                  {this.context.basketData.map((row, id) => {
                                    let passenger = this.context.flightData
                                      .find((f) => f.id == row.flightId)
                                      ?.passengers.find(
                                        (i) => i.extId == row.passengerExtId
                                      );
                                    let product =
                                      this.context.productsData.find(
                                        (p) => p.id == row.productId
                                      );
                                    let variant = row?.variant?.includes("~=")
                                      ? row?.variant?.replace("~=", " ")
                                      : row?.variant;
                                    return (
                                      <tbody key={id}>
                                        {row.type != this.lastCategory && (
                                          <tr>
                                            <th
                                              colSpan="2"
                                              className="basketDropdown__serviceGroupCaption"
                                            >
                                              <div
                                                style={{
                                                  textTransform: "capitalize",
                                                  marginTop: "11px",
                                                  marginBottom: "11px",
                                                }}
                                              >
                                                {row.type}
                                              </div>
                                            </th>
                                          </tr>
                                        )}
                                        {
                                          <tr style={{ display: "none" }}>
                                            <td>
                                              {(this.lastCategory = row.type)}
                                            </td>
                                          </tr>
                                        }
                                        <tr className="basketDropdown__serviceRow">
                                          <td className="basketDropdown__deleteBtnCol">
                                            <div
                                              className="basketDropdown__deleteBtn"
                                              onClick={() => {
                                                this.context.RemoveFromCart(
                                                  row.id
                                                );
                                                if (
                                                  this.context.basketData
                                                    ?.length == 0
                                                ) {
                                                  handleClickAway();
                                                }
                                              }}
                                            ></div>
                                          </td>
                                          <td className="basketDropdown__serviceNameCol">
                                            <span style={{ width: "100%" }}>
                                              {passenger && passenger.firstName}{" "}
                                              {passenger && passenger.lastName}
                                              <br />
                                              {
                                                product?.translations?.find(
                                                  (item) => item.key == "title"
                                                )?.content
                                              }{" "}
                                              {
                                                <span
                                                  style={{
                                                    display: "block",
                                                    maxWidth: "150px",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {variant}
                                                </span>
                                              }
                                            </span>
                                          </td>
                                          <td className="basketDropdown__servicePriceCol">
                                            <span>
                                              {row.price?.toFixed(2)}{" "}
                                              {row.currency}
                                            </span>
                                          </td>
                                        </tr>
                                      </tbody>
                                    );
                                  })}
                                </table>
                              </div>
                            )}
                          </div>
                          <div className="BasketDropdownFooter">
                            <div className="BasketDropdownFooter__info">
                              <div className="BasketDropdownFooter__title">
                                Total
                              </div>
                              <span className="BasketDropdownFooter__price">
                                {priceTotal?.toFixed(2)} EUR
                              </span>
                            </div>
                            <button
                              className="BasketDropdownFooter__button"
                              onClick={() => {
                                this.props.OnSelectedSectionChange(5);
                              }}
                            >
                              <div>
                                <FormattedMessage id="payment.checkout" />
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </ClickAwayListener>
              </div>
            </div>
            <div className="wizardHeader__descRow ">
              <div>
                {this.props.activeSection === 5 && (
                  <FormattedMessage id="payment.PleaseCheck" />
                )}
              </div>
            </div>
          </div>
          <div className="wizardFooter">
            <div
              className={
                "wizardFooter__button wizardFooter__button--type-prev " +
                (this.props.activeSection == 1
                  ? "wizardFooter__button--disabled"
                  : "")
              }
              onClick={Prev}
            >
              <span>
                <FormattedMessage id="common.Back" />
              </span>
            </div>
            <div
              className="wizardFooter__button   wizardFooter__button--type-next "
              onClick={Next}
            >
              <span>
                <FormattedMessage id="common.Next" />
              </span>
            </div>
            <div className="wizardFooter__cart ">
              <div className="basketTooltipButton undefined">
                <div>
                  <div className="wizardCartButton">
                    <div className="wizardCartButton__link ext-cart-link" />
                    <span className="wizardCartButton__cartIcon" />
                    <span>{priceTotal}.00 EUR</span>
                  </div>
                </div>
                <div className="basketTooltipButton__tooltipRoot basketTooltipButton__tooltipRoot--hidden" />
              </div>
            </div>
          </div>
        </div>
      );
  }

  constructor(props) {
    super(props);
    this.state = {
      IsMenuActive: false,
      IsCartPopupActive: false,
      lastCategory: "",
    };
  }
}
SectionBasket.contextType = AppContext;
export default withRouter(SectionBasket);
