import React, { useContext } from "react";
import { AppContext } from "../../AppContext";
import classes from "../../css/Banner.module.scss";

const Banner = ({ location }) => {
  const context = useContext(AppContext);

  return (
    <>
      {context.selectedPassenger?.banners.map((banner) => {
        if (location === banner.position) {
          const languageInx = banner.translations.findIndex(
            (language) => language.lang === context.language
          );

          return languageInx === -1 ? null : (
            <div key={banner.id} className={classes.container}>
              <img
                src={`${
                  process.env.REACT_APP_WS_URL +
                  "/Files/" +
                  banner.translations[languageInx].imageId
                }`}
                alt=""
              />
            </div>
          );
        } else {
          return null;
        }
      })}
    </>
  );
};
export default Banner;
