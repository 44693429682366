import React, {useState}from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import {Dialog,DialogTitle,DialogActions,Button,DialogContent} from '@material-ui/core';

export default function RandomSeatNotification(props) {
	const intl = useIntl();
	return (
			<Dialog
				open={props.visible}
				onClose={(event, reason) => {
					if(reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
						props.CloseRandomSeatNotification(props.activeSectionIndex)
					}}}
					>
                    <DialogTitle><FormattedMessage id="seating.randomSeatPopupTitle" /></DialogTitle>
                    <DialogContent>
						<FormattedMessage id="seating.randomSeatPopupContent" />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => props.CloseRandomSeatNotification(props.activeSectionIndex)} color="primary"><FormattedMessage id="common.Close" /></Button>
                    </DialogActions>
			</Dialog>
	);
}
