import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

const InfantDialogContent = ({
  direction = 0,
  notRelatedInfants,
  notRelatedParents,
  parentChoiceHandler,
  infantIdHandler,
  notRelatedParentsReturn,
  sameInfantBothDirections,
}) => {
  const [value, setValue] = useState("");
  const [valueReturn, setValueReturn] = useState("");
  const [IsBtnDisabled, setIsBtnDisabled] = useState(true);

  useEffect(() => {
    if (value === "") {
      setIsBtnDisabled(true);
    } else setIsBtnDisabled(false);
    if (sameInfantBothDirections) {
      if (valueReturn === "") {
        setIsBtnDisabled(true);
      } else setIsBtnDisabled(false);
    }

    parentChoiceHandler(IsBtnDisabled);
  }, [
    valueReturn,
    value,
    sameInfantBothDirections,
    IsBtnDisabled,
    parentChoiceHandler,
  ]);

  useEffect(() => {
    setValue("");
    setValueReturn("");
  }, [notRelatedInfants.length]);
  useEffect(() => {
    notRelatedInfants && infantIdHandler(notRelatedInfants[0]?.extId);
  }, [infantIdHandler, notRelatedInfants]);

  if (notRelatedInfants?.length === 0 || !notRelatedInfants) return null;

  return (
    <>
      {notRelatedInfants?.[0] && (
        <h3 id={notRelatedInfants[0].extId}>
          {/* {notRelatedInfants[0].firstName[0].toUpperCase() +
            notRelatedInfants[0].firstName.slice(1).toLowerCase()}{" "} */}
          {/* {notRelatedInfants[0].lastName[0].toUpperCase() +
            notRelatedInfants[0].lastName.slice(1).toLowerCase()} */}
          {notRelatedInfants[0].firstName} {notRelatedInfants[0].lastName}
        </h3>
      )}
      <h3>
        {direction === 1 ? (
          <FormattedMessage id="myBooking.InfantRelation.Return" />
        ) : (
          <FormattedMessage id="myBooking.InfantRelation.Outbound" />
        )}
      </h3>

      {notRelatedParents.length === 0 && notRelatedInfants[0] ? (
        <h2>
          <FormattedMessage id="myBooking.InfantRelation.NoParents" />
        </h2>
      ) : (
        <select
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            parentChoiceHandler(e);
          }}
          name="Parent list"
        >
          <option value="" disabled hidden></option>
          {notRelatedParents?.map((parent, i) => (
            <option key={parent.extId} id={parent.extId} value={parent.extId}>
              {/* {parent.firstName[0].toUpperCase() +
                parent.firstName.slice(1).toLowerCase()}{" "}
              {parent.lastName[0].toUpperCase() +
                parent.lastName.slice(1).toLowerCase()} */}
              {parent.firstName} {parent.lastName}
            </option>
          ))}
        </select>
      )}

      {sameInfantBothDirections && (
        <>
          <h3>
            <FormattedMessage id="myBooking.InfantRelation.Return" />
          </h3>
          {notRelatedParentsReturn?.length === 0 ? (
            <h2>
              <FormattedMessage id="myBooking.InfantRelation.NoParents" />
            </h2>
          ) : (
            <select
              value={valueReturn}
              onChange={(e) => {
                setValueReturn(e.target.value);
                parentChoiceHandler(e);
              }}
              name="Parent list"
            >
              <option value="" disabled hidden></option>
              {notRelatedParentsReturn?.map((parent, i) => (
                <option
                  key={parent.extId}
                  id={parent.extId}
                  value={parent.extId}
                >
                  {/* {parent.firstName[0].toUpperCase() +
                    parent.firstName.slice(1).toLowerCase()}{" "}
                  {parent.lastName[0].toUpperCase() +
                    parent.lastName.slice(1).toLowerCase()} */}
                  {parent.firstName} {parent.lastName}
                </option>
              ))}
            </select>
          )}
        </>
      )}
    </>
  );
};

export default InfantDialogContent;
