import React from "react";
import classes from "../LiveChat.module.scss";
import { FormattedMessage } from "react-intl";

const DialogWindow = ({ onClose, onConfirm }) => {
  return (
    <div className={classes.closeContainer}>
      <h3>
        {/* <FormattedMessage id="chat.endChatDialog.Text" />{" "} */}
        <FormattedMessage id="chat.endChatDialog.Text" />{" "}
      </h3>
      <div>
        <button onClick={() => onConfirm()}>
          <FormattedMessage id="chat.endChatDialog.yesBtn" />
        </button>
        <button className={classes.btn_no} onClick={onClose}>
          <FormattedMessage id="chat.endChatDialog.noBtn" />
        </button>
      </div>
    </div>
  );
};

export default DialogWindow;
