
export default class API_Utils {

  static AuthTokenName = "SLXCheckIn_PAXAuthToken" + process.env.REACT_APP_WS_URL;
  static AuthToken = localStorage[this.AuthTokenName];

  static WebServiceJSON = (requestMethod, url, bodyData, successCallback, failCallback) => {
    this.CallFetchMethod(requestMethod, url, bodyData).then(response => {
      
      if (response.status == 204) { return null }
      else if (response.ok) { return response.json() }
      else { this.HandleError(response) }
    })
      .then(data => { if (successCallback != null) successCallback(data) })
      .catch((error) => { if (failCallback != null) failCallback(error) });
  }

  static WebServiceText = (requestMethod, url, bodyData, successCallback, failCallback) => {
    this.CallFetchMethod(requestMethod, url, bodyData).then(response => {
      if (response.ok) { return response.text() }
      else { this.HandleError(response) }
    })
      .then(data => { if (successCallback != null) successCallback(data) })
      .catch((error) => { if (failCallback != null) failCallback(error) });
  }

  static WebServiceAction = (requestMethod, url, bodyData, successCallback, failCallback) => {
    this.CallFetchMethod(requestMethod, url, bodyData).then(response => {
      if (response.ok) {
        if (successCallback != null) successCallback();
      }
      else { this.HandleError(response) }
    })
      .catch((error) => { if (failCallback != null) failCallback(error) });
  }


  static CallFetchMethod = (requestMethod, url, bodyData) => {
    const requestOptions = {
      method: requestMethod,
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.AuthToken },
    };
    if (bodyData) {
      requestOptions.body = JSON.stringify(bodyData);
    }
    return fetch(process.env.REACT_APP_WS_URL + '/' + url, requestOptions);
  }


  static HandleError = (response) => {
    if (response.status == 401) {
      localStorage.removeItem(this.AuthTokenName);
      window.location.reload();
    }
    throw new Error(response.status);
  }

  static logOut = () => {

    if (this.AuthToken)
    {
      this.WebServiceAction("GET", 'Login/terminate',null,null,null);
      localStorage.removeItem(this.AuthTokenName);
    }
    if (window.location.pathname != "/") window.location = window.location.origin
  };

}
