
import React, {useContext} from 'react';
import classNames from 'classnames/bind';
import { format } from 'date-fns'
import { AppContext } from "./../AppContext";


export default function FlightSegment(props) {
    const context = useContext(AppContext);
    return (
                <div>
                    <div className="pageCloud">
                        <div className="segmentsSelector">

                            {context.flightData && context.flightData.map((row, id) => {
                                    let flightClasses = classNames({
                                        'segmentsSelector__item': true,
                                        'segmentItem': true,
                                        'segmentItem--isCurrent': context.selectedFlightIndex === id,
                                        'segmentItem--direction-out': id === 0,
                                        'segmentItem--direction-in': id === 1
                                    });
                                    return (
                                        <div className={flightClasses} key={id}
                                             onClick={() => context.OnCurrentFlightChanged(id)}
                                        >
                                            <div className="segmentItem__date">
                                                {format(new Date(row.std), 'd MMM')}
                                            </div>
                                            <div className="segmentItem__airportsList">
                                                <div className="segmentItem__airport">
                                                    <div>
                                                        <div className="segmentItem__airportName">
                                                            <div>{row.depAirportCode} - {row.depAirportName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="segmentItem__airport">
                                                    <div>
                                                        <div className="segmentItem__airportName">
                                                            <div>{row.arrAirportCode} - {row.arrAirportName}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            )
                            }

                        </div>

                    </div>
                </div>

);
}
