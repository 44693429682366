import React from "react";
import { AppContext } from "../AppContext";
import SeatPlan from "./SeatPlan";
import { FormattedMessage } from "react-intl";
import Banner from "../Components/Banner/Banner";

class SeatInfo extends React.Component {
  render() {
    return (
      <div className="pageCloud">
        <div className="pageCloud__title pageCloud__title--hideForMobile">
          <div>
            <FormattedMessage id="seating.seatInformation" />
          </div>
        </div>
        <div>
          <div className="seats">
            <div className="seat seat--selected">
              <div className="seat__title">
                <FormattedMessage id="seating.Selected" />
              </div>
            </div>
            <div className="seat seat--occupied">
              <div className="seat__title">
                <FormattedMessage id="seating.Occupied" />
              </div>
            </div>
            <div className="seat seat--suggested">
              <div className="seat__title">
                <FormattedMessage id="seating.AllocatedSeat" />
              </div>
            </div>
            <div className="seat seat--infant">
              <div className="seat__title">
                <FormattedMessage id="seating.Infant" />
              </div>
            </div>
          </div>
          <div className="seatsList">
            {this.context.productsData
              ?.filter((p) => p.type == "Seat")
              .map((prod) => {
                let passengerProd =
                  this.context.selectedPassenger?.availableProducts.find(
                    (a) => a.productId == prod?.id
                  );

                let minPrice = null;
                if (
                  this.context.selectedPassenger?.availableProducts.some(
                    (a) => a.variantFilter && a.productId == prod?.id
                  )
                ) {
                  minPrice = this.context.selectedPassenger?.availableProducts
                    .filter((a) => a.productId == prod?.id)
                    .sort(function (a, b) {
                      return a.price - b.price;
                    })
                    .find((e) => true)?.price;
                }

                return (
                  <div
                    key={prod.id}
                    className="seatsList__item serviceSeat serviceSeat--isInfoShow serviceSeat--isInfoShow "
                  >
                    <div className="serviceSeat__content">
                      <img
                        alt="Seat"
                        className="serviceSeat__image"
                        src={
                          process.env.REACT_APP_WS_URL +
                          "/Files/" +
                          prod?.imageId
                        }
                      />
                      <div className="serviceSeat__info">
                        <span>
                          <span className="serviceSeat__price">
                            {minPrice ? (
                              <div>
                                <FormattedMessage id="seating.PriceFrom" />{" "}
                                {minPrice.toFixed(2)}
                              </div>
                            ) : (
                              passengerProd?.price?.toFixed(2)
                            )}{" "}
                            {passengerProd?.currency}
                          </span>
                        </span>
                        <div className="serviceSeat__title">
                          {
                            prod?.translations?.find(
                              (item) => item.key == "title"
                            )?.content
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>

        <Banner location="SeatLeft" />
        <div className="seatingPageInfo  ">
          <div className="seatingPageInfo__title">
            <FormattedMessage id="seating.moreInformation" />
          </div>
          <div
            className="seatingPageInfo__contentWrapper"
            style={{ height: "auto" }}
          >
            <div className="ReactCollapse--content">
              <div className="seatingPageInfo__content">
                <div>
                  <FormattedMessage id="seating.moreinformationdropdown" />{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SeatInfo.contextType = AppContext;
export default SeatInfo;
