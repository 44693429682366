import React, { useState, useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router";
import { AppContext } from "./AppContext";
import Seating from "./Seating";
import Baggage from "./Baggage";
import Services from "./Services";
import Meals from "./Meals";
import Cart from "./Cart";
import MyBooking from "./MyBooking";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import { IntlProvider } from "react-intl";
import SectionBasket from "./Components/SectionBasket";
import "./css/primary.css";
import "./css/rootView.css";
import "./css/ASG_design.scss";
import LoadingScreen from "./LoadingScreen";
import RandomSeatNotification from "./Components/RandomSeatNotification";
import LiveChat from "./Components/LiveChat/LiveChat";

const App = (props) => {
  const [isApprovedRandomSeats, SetApprovedRandomSeats] = useState(false);
  const [randomSeatNotificationOpen, SetRandomSeatNotificationOpen] =
    useState(false);
  const context = useContext(AppContext);
  const selectedSectionChange = (index) => {
    let links = [
      "/seating",
      "/baggage",
      "/meals",
      "/services",
      "/cart",
      "/mybooking",
    ];

    if (activeSectionIndex === 1 && index !== 1 && !isApprovedRandomSeats) {
      // If Some passenger don't have seat, but seats are available.
      if (
        context.flightData.some((f) =>
          f.passengers.some(
            (p) =>
              !context.GetPassengerSeat(p.extId ?? p.id) &&
              !p.isInfant &&
              p.availableProducts.some((ap) =>
                context.productsData.some(
                  (prod) => prod.id == ap.productId && prod.typeId === 1
                )
              )
          )
        )
      ) {
        SetRandomSeatNotificationOpen(true);
        return;
      }
    }
    SetActiveSectionIndex(index);
    if (index == 1) context.OnCurrentFlightChanged(0);
    else context.OnCurrentFlightChanged(-1);
    const { history } = props;
    if (history) history.push(links[index - 1]);
  };

  const CloseRandomSeatNotification = (index) => {
    SetApprovedRandomSeats(true);
    SetRandomSeatNotificationOpen(false);
  };

  const getMenuSectionIndex = () => {
    let currentUrl = window.location.href;
    let currentRootUrl = window.location.origin;
    if (currentUrl.startsWith(currentRootUrl + "/baggage")) {
      return 2;
    }
    if (currentUrl.startsWith(currentRootUrl + "/meals")) {
      return 3;
    }
    if (currentUrl.startsWith(currentRootUrl + "/services")) {
      return 4;
    }
    if (currentUrl.startsWith(currentRootUrl + "/cart")) {
      return 5;
    } // TODO: Check if user can go there
    if (currentUrl.startsWith(currentRootUrl + "/mybooking")) {
      return 6;
    }
    return 1;
  };

  const [activeSectionIndex, SetActiveSectionIndex] = useState(() => {
    const initialState = getMenuSectionIndex(props);
    return initialState;
  });

  useEffect(() => {
    //set correct state after back button is clicked
    window.onpopstate = (e) => {
      SetActiveSectionIndex(getMenuSectionIndex(props));
      if (context.flightData != null) {
        context.SetDataLoading(false);
      }
    };
  }, []);

  if (context.IsDataLoading) return <LoadingScreen />;

  return (
    <div>
      <IntlProvider
        locale={context.language}
        messages={
          context.translations[context.language] ?? context.translations["en"]
        }
      >
        <RandomSeatNotification
          visible={randomSeatNotificationOpen}
          activeSectionIndex={activeSectionIndex}
          CloseRandomSeatNotification={CloseRandomSeatNotification}
        />

        <div id="appRoot">
          <div className="dialogsContext">
            <div className="paxshopApp seating">
              <div id="paxshopWrapper" className="paxshopApp__wrapper">
                <div className="paxshopApp__mainContent">
                  <div className="checkIn checkIn--isTopSegmentBar " />
                  <Header
                    activeSection={activeSectionIndex}
                    OnSelectedSectionChange={selectedSectionChange}
                    logOut={context.API.logOut}
                    selectedLanguage={context.language}
                  />

                  <div className="topLevelContainer">
                    <div className="page">
                      <SectionBasket
                        activeSection={activeSectionIndex}
                        OnSelectedSectionChange={selectedSectionChange}
                      ></SectionBasket>
                      <Route exact path="/mybooking">
                        <MyBooking />
                      </Route>
                      <Route exact path={["/", "/seating"]}>
                        <Seating />
                      </Route>
                      <Route exact path="/baggage">
                        <Baggage />
                      </Route>
                      <Route exact path="/meals">
                        <Meals />
                      </Route>
                      <Route exact path="/services">
                        <Services />
                      </Route>
                      <Route exact path="/cart">
                        <Cart selectedSectionChange={selectedSectionChange} />
                      </Route>
                    </div>
                  </div>
                </div>
                <div className="paxshopApp__buffer" />
              </div>
              {/* Uncomment when chat will released */}
              <LiveChat />
              <Footer />
            </div>
          </div>
        </div>
      </IntlProvider>
    </div>
  );
};

export default withRouter(App);
