import React, { useState, useContext,useEffect } from 'react';
import { Button, DialogTitle, DialogContent, Dialog, DialogActions } from '@material-ui/core';
import { AppContext } from '../AppContext'
import { FormattedMessage } from "react-intl";
import { CircularProgress } from '@material-ui/core';
import SuccessIcon from '../images/green-checkmark-line.png'

export default function PaymentAcceptedDialog(props) {
  const context = useContext(AppContext);

useEffect(() => {

  if (!GetPaidStatus())
  {
    const interval = setInterval(() => {
      RefreshAndCheckPayment()
    }, 10000);
    return () => clearInterval(interval);
  }

  }, []);


  function GetPaidStatus(){
    if (context?.transactions.length==0) {props.handleClose(); return true; }
    return context?.transactions[context?.transactions.length-1].paid==true;
  }

  function RefreshAndCheckPayment()
  {
    if (!GetPaidStatus())
      context.CheckDataHashOnServer();
  }

  function Close()
  {
      props.handleClose();
  }

  return (
    <Dialog disableBackdropClick open onClose={() => Close()}>
    <DialogTitle>
      {GetPaidStatus() && <FormattedMessage id="payment.sucessful" />}
      {!GetPaidStatus() && <FormattedMessage id="payment.waitingForConfirmTitle" />}
    </DialogTitle>
    <DialogContent>
        <div style={{ textAlign: 'center', marginBottom: '15px' }}>
          {GetPaidStatus() && <img alt='Success' style={{ width: '150px' }} src={SuccessIcon}/>}
          {!GetPaidStatus() && <CircularProgress size={60}/>}
        </div>
        <div>
        {GetPaidStatus() && <FormattedMessage id="payment.SeeTransactionDetails" />} 
        {!GetPaidStatus() && <FormattedMessage id="payment.waitingForConfirm" />} 
          </div>
    </DialogContent>
    <DialogActions>
        <Button onClick={() => Close()} color="primary"><FormattedMessage id="common.Close" /></Button>
    </DialogActions>
    </Dialog>

  );
}
