import React, { useState } from 'react';
import { FormattedMessage, useIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';

export default function ContactUsButton(props) {
	const intl = useIntl();
	return (
		<li className="menuMisc__item menuMisc__item--withText ">
			<Dialog
				open={props.IsModalOpen}
				onClose={()=>props.SetModalOpen(false)}>
				<div>
					<div className="pxModal__closeButton" onClick={()=>props.SetModalOpen(false)}></div>
					<div dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: 'header.ContactDialogText' }) }} />
				</div>
			</Dialog>
			<div onClick={() => props.SetModalOpen(true)}>
				<i className="menuMisc__icon icon contacts" />
				<span className="menuMisc__text "><FormattedMessage id="header.ContactUs" /></span>
			</div>
		</li>
	);
}