import React, { useState, useContext, useEffect, useRef } from "react";
import { AppContext } from "../../../AppContext";
import classes from "../LiveChat.module.scss";
import Message from "../Components/Message";
import Logo from "../../../images/logo.png";
import SendButton from "../../../images/live-chat/send_button.svg";
import DialogWindow from "./Dialog";
import { FormattedMessage, useIntl } from "react-intl";

const ChatWindow = ({
  widgetOpenedHandler,
  getMessages,
  sessionId,
  messages,
  isOnline,
  sessionClosed,
  setSessionClosed,
}) => {
  const context = useContext(AppContext);
  const [messageError, setMessageError] = useState(false);
  const [messageValue, setMessageValue] = useState("");
  const [endDialogOpen, setEndDialogOpen] = useState(false);

  const intl = useIntl();
  const placeholder = intl.formatMessage({ id: "chat.inputPlaceholder" });

  const sessionClosedHandler = (state) => {
    setSessionClosed(state);
  };

  const closeConversationHandler = () => {
    if (!sessionId) return;

    context.API.WebServiceJSON(
      "DELETE",
      "chat/session",
      { sessionId: sessionId, type: 1 },
      () => {
        sessionClosedHandler(true);
        setEndDialogOpen(false);
      },
      null
    );
  };

  const bottom = useRef(null);

  const scrollToBottom = () => {
    bottom.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, sessionClosed]);

  const submitHandler = (e) => {
    e.preventDefault();
    setMessageError(false);

    if (messageValue === "") {
      setMessageError(true);
      return;
    } else {
      sessionClosedHandler(false);
      context.API.WebServiceJSON(
        "GET",
        `chat`,
        null,
        (z) => {
          if (z.id !== 0) {
            context.API.WebServiceJSON(
              "POST",
              "chat/message",
              { message: messageValue },
              () => {
                getMessages();
              },
              null
            );
          } else {
            const data = {
              text: messageValue,
              flightId: +context.selectedFlight.id,
              userLanguage: context.language,
            };

            context.API.WebServiceJSON(
              "POST",
              "chat/session",
              data,
              () => {
                getMessages();
              },
              null
            );
          }
        },
        null
      );
    }

    setMessageValue("");
  };

  return (
    <div className={classes.conversationContainer}>
      <div className={classes.header}>
        <div>
          <span
            className={`${classes.status} ${!isOnline && classes.offline}`}
          ></span>
          <img src={Logo} alt="Smartlynx logo" />
          <p>SmartLynx Agent</p>
        </div>
        <div>
          {endDialogOpen && (
            <DialogWindow
              onConfirm={closeConversationHandler}
              onClose={() => setEndDialogOpen(false)}
            />
          )}
          {sessionId !== 0 && (
            <button
              disabled={sessionClosed}
              onClick={() => setEndDialogOpen(true)}
              className={classes.endChatBtn}
            >
              <FormattedMessage id="chat.endBtn" />
            </button>
          )}
        </div>
        <span onClick={() => widgetOpenedHandler(false)}>&#10006;</span>
      </div>

      <div className={classes.dialog}>
        <Message
          messageText={
            isOnline ? (
              <FormattedMessage id="chat.welcomeMessageOnline" />
            ) : (
              <FormattedMessage id="chat.welcomeMessageOffline" />
            )
          }
          fromClient={0}
        />
        {messages?.map((message, i) => {
          return (
            <Message
              key={message.id}
              messageText={message.text}
              messageDate={message.timeStamp}
              fromClient={!message.direction}
              // watched={message.watched}
            />
          );
        })}

        {sessionClosed && sessionId !== 0 && (
          <div className={classes.closed}>
            <FormattedMessage id="chat.closedMsg" />
          </div>
        )}
        <div ref={bottom}></div>
      </div>
      <form autoComplete="off" onSubmit={submitHandler}>
        <div className={classes.actions}>
          {/* Attach file button */}
          {/* <div className={classes.attach}>
      <label htmlFor="attachFile">
        <img src={AtachFile} alt="Attach file icon" />
      </label>
      <input id="attachFile" type="file" />
      </div> */}
          <div>
            <div
              className={`${classes.input} ${
                messageError && classes["input-error"]
              }`}
            >
              <input
                id="message"
                type="text"
                placeholder={placeholder}
                value={messageValue}
                onChange={(e) => {
                  setMessageError(false);
                  setMessageValue(e.target.value);
                }}
              />
            </div>
            {messageError && (
              <p className={classes["input-error-text"]}>
                <FormattedMessage id="chat.errorMsg" />
              </p>
            )}
          </div>

          <button type="submit" className={classes.send}>
            <img
              src={SendButton}
              alt="Send button icon"
              className={classes["send-icon"]}
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChatWindow;
