import React from 'react';
import ReactDOM from 'react-dom';
import AppContextProvider from "./AppContext.js";
import * as serviceWorker from './serviceWorker';
import App from './App';
import Main from './Main';
import Error404 from './Error404';
import BoardingPass from './BoardingPass';
import Receipt from './Receipt';
import EcommPayStart from './EcommPay_Start';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

ReactDOM.render(
        // <React.StrictMode>
          <AppContextProvider>
              <Router>
                <Switch>
                  <Route path={"/"} exact >
                    <Main></Main>
                  </Route>
                  <Route path={["/seating", "/baggage", "/meals", "/services", "/cart", "/mybooking"]} exact >               
                      <App></App>
                  </Route>
                  <Route path={"/boardingpass"} exact >
                    <BoardingPass></BoardingPass>
                  </Route>
                  <Route path={"/ecommpay/pay"} exact >
                    <EcommPayStart></EcommPayStart>
                  </Route>
                  <Route path={"/receipt"} exact >
                    <Receipt></Receipt>
                  </Route>
                  <Route component={Error404} />
                </Switch>
              </Router>
              </AppContextProvider>
        // </React.StrictMode>
        ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
