import React, { Component } from "react";
import messages_en from "./translations/en.json";
import messages_ru from "./translations/ru.json";
import messages_lv from "./translations/lv.json";
import messages_ee from "./translations/et.json";
import messages_de from "./translations/de.json";
import API from "./API_Utils";
const pm = require("picomatch");

const messages = {
  en: messages_en,
  ru: messages_ru,
  lv: messages_lv,
  ee: messages_ee,
  et: messages_ee,
  de: messages_de,
};

export const AppContext = React.createContext();
const Provider = AppContext.Provider;

class AppContextProvider extends Component {
  state = {
    bookingNumber: null,
    basketData: [],
    selectedPassengerIndex: 0,
    selectedFlightIndex: 0,
    selectedPassenger: null,
    selectedFlight: null,
    language:
      localStorage.getItem("SLXCheckIn_Lang") ??
      (messages[navigator?.language?.split(/[-_]/)[0]] != null
        ? navigator?.language?.split(/[-_]/)[0]
        : "en"),
    transactions: [],
    flightData: [],
    passengers: [],
    allPassengers: [],
    productsData: [],
    IsDataLoading: true,
    generalNotification: null,
    receivedDataHash: null,
    paymentOptions: null,
  };

  basketChanged = (newBasketData) => {
    this.setState({ basketData: newBasketData });
  };

  LangChange = (newLanguage) => {
    localStorage["SLXCheckIn_Lang"] = newLanguage;
    this.setState({ language: newLanguage }, () => {
      if (API.AuthToken != null) this.LoadProductsData();
      this.LoadNotification();
    });
  };

  RemoveFromCartByParams = (productId, flightId, passengerExtId, funcAfter) => {
    let array = [...this.state.basketData];
    let BasketProductToRemove = array.find(function (b) {
      return (
        b.productId === productId &&
        b.flightId === flightId &&
        b.passengerExtId === passengerExtId
      );
    });

    if (BasketProductToRemove != null) {
      return this.RemoveFromCart(BasketProductToRemove.id, funcAfter);
    }
  };

  basketChanged = (newBasketData) => {
    function compare(a, b) {
      if (a.item > b.item) {
        return -1;
      }
      if (a.item < b.item) {
        return 1;
      }
      return 0;
    }
    newBasketData.sort(compare);

    this.setState({ basketData: newBasketData });
  };

  CurrentPaxChanged = (newIndex) => {
    this.setState({
      selectedPassenger: this.state.passengers[newIndex],
      selectedPassengerIndex: newIndex,
    });
  };

  OnCurrentFlightChanged = (newIndex) => {
    if (newIndex >= 0) {
      this.setState(
        {
          selectedFlight: this.state.flightData[newIndex],
          selectedFlightIndex: newIndex,
          passengers: this.state.flightData[newIndex].passengers.filter(
            (p) => p.type != "INF"
          ),
        },
        () => {
          this.CurrentPaxChanged(0);
        }
      );
    } else {
      this.setState(
        {
          selectedFlight: this.state.flightData[0],
          selectedFlightIndex: newIndex,
          passengers: this.state.allPassengers.filter((p) => p.type != "INF"),
        },
        () => {
          this.CurrentPaxChanged(0);
        }
      );
    }
  };

  SetDataLoading = (state) => {
    if (this.state.IsDataLoading !== state)
      this.setState({ IsDataLoading: state });
  };

  LoadAllPassengers = () => {
    const array1 = this.state?.flightData[0]?.passengers;
    const array2 = this.state?.flightData[1]?.passengers;
    const unique = [...array1];

    if (array2 != null) {
      array2.forEach((row) => {
        if (!array1.find((item) => item.person_Id === row.person_Id)) {
          unique.push(row);
        }
      });
    }
    this.setState({ allPassengers: unique });
  };

  render() {
    return (
      <Provider
        value={{
          bookingNumber: this.state.bookingNumber,
          languagesAvailable: [
            // { code: "ENG", title: "English" },
            // { code: "LAV", title: "Latviešu" },
            // { code: "EST", title: "Eesti" },
            // { code: "RUS", title: "Русский" },
            // { code: "GER", title: "Deutsch" },
            { code: "en", title: "English" },
            { code: "lv", title: "Latviešu" },
            { code: "ee", title: "Eesti" },
            { code: "ru", title: "Русский" },
            { code: "de", title: "Deutsch" },
          ], //['en', 'lv' ,'ee' ,'ru']
          language: this.state.language,
          LangChange: this.LangChange,
          basketData: this.state.basketData,
          selectedPassenger: this.state.selectedPassenger,
          selectedFlight: this.state.selectedFlight,
          selectedPassengerIndex: this.state.selectedPassengerIndex,
          selectedFlightIndex: this.state.selectedFlightIndex,
          AddToCart: this.AddToCart,
          RemoveFromCart: this.RemoveFromCart,
          RemoveFromCartByKey: this.RemoveFromCartByKey,
          RemoveFromCartByParams: this.RemoveFromCartByParams,
          basketChanged: this.basketChanged,
          flightData: this.state.flightData,
          transactions: this.state.transactions,
          productsData: this.state.productsData,
          passengers: this.state.passengers,
          allPassengers: this.state.allPassengers,
          CurrentPaxChanged: this.CurrentPaxChanged,
          OnCurrentFlightChanged: this.OnCurrentFlightChanged,
          GetPassengerSeat: this.GetPassengerSeat,
          translations: messages,
          IsDataLoading: this.state.IsDataLoading,
          LoadPassengerData: this.LoadPassengerData,
          SetDataLoading: this.SetDataLoading,
          CreateTransactionFromCart: this.CreateTransactionFromCart,
          generalNotification: this.state.generalNotification,
          CheckDataHashOnServer: this.CheckDataHashOnServer,
          PaymentOptions: this.state.paymentOptions,
          API: API,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }

  componentDidMount() {
    this.LoadNotification();
    // Constantly check data hash on server
    const interval = setInterval(
      () => this.CheckDataHashOnServer(),
      (process.env.REACT_APP_AutoRefresh_Freq_Seconds ?? 60) * 1000
    );
    if (API.AuthToken != null) {
      this.LoadPassengerData((result) => {
        if (this.state.flightData && this.state.flightData.length > 0) {
          this.OnCurrentFlightChanged(0);
          this.LoadAllPassengers();
          this.LoadProductsData();
          this.SetDataLoading(false);
        }
      });
    } else if (
      new URLSearchParams(window.location.search).get("backend") == "1"
    ) {
    } else API.logOut();
  }

  CheckDataHashOnServer = () => {
    if (this.state.receivedDataHash != null) {
      API.WebServiceText("GET", "Reservation/hash", null, (hash) => {
        if (this.state.receivedDataHash != hash) {
          this.LoadPassengerData();
        }
      });
    }
  };

  CreateTransactionFromCart = (request) => {
    this.SetDataLoading(true);

    API.WebServiceText(
      "POST",
      "Transactions/create",
      request,
      (id) =>
        this.LoadPassengerData((p) => {
          window.location.replace(
            process.env.REACT_APP_WS_URL + "/Transactions/pay/" + id
          );
        }),
      this.LoadPassengerData()
    );
  };

  LoadPassengerData = (onSuccess, onFail) => {
    API.WebServiceJSON(
      "GET",
      "Reservation/GetReservationForPassenger",
      null,
      (result) => {
        if (result.exist) {
          this.basketChanged(result.itemsInCart);
          this.setState(
            {
              flightData: result.flights,
              transactions: result.transactions,
              bookingNumber: result.bookingNr,
              receivedDataHash: result.hashCode,
              paymentOptions: result.paymentOptions,
            },
            () => {
              if (onSuccess != null) {
                onSuccess(result);
              }
            }
          );
        }
      },
      (error) => {
        if (onFail != null) {
          onFail();
        }
      }
    );
  };

  AddToCart = (
    productId,
    passengerExtId,
    flightId,
    variant = null,
    funcAfter
  ) => {
    // flexible prices depending on filter

    //product depending on filter
    let passengerProd = this.state.flightData
      .find((f) => f.id === flightId)
      ?.passengers.find((p) => p.extId == passengerExtId)
      ?.availableProducts.find(
        (a) =>
          (a.variantFilter + "")
            .split(",")
            .some((x) => pm.matchBase(variant, x)) && a.productId == productId
      );
    if (passengerProd == null) {
      passengerProd = this.state.flightData
        .find((f) => f.id === flightId)
        ?.passengers.find((p) => p.extId == passengerExtId)
        ?.availableProducts.find(
          (a) => a.variantFilter == null && a.productId == productId
        );
    }
    if (passengerProd == null) return;

    let postItem = {
      currency: passengerProd.currency,
      flightId: flightId,
      passengerExtId: passengerExtId,
      price: passengerProd.price,
      productId: productId,
      variant: variant,
    };

    API.WebServiceJSON(
      "POST",
      "Cart",
      postItem,
      (data) => {
        let basketData = [...this.state.basketData];
        basketData.push(data);
        this.basketChanged(basketData);
        if (funcAfter != null) funcAfter(true);
      },
      (error) => {
        console.log("Error adding item to cart", error);
        if (funcAfter != null) funcAfter(false);
        return false;
      }
    );
  };

  RemoveFromCart = (id, funcAfter) => {
    //remove item from server, if ales gut, then remove item from cart
    API.WebServiceAction(
      "DELETE",
      "Cart/" + id,
      null,
      (data) => {
        this.basketChanged(this.state.basketData.filter((x) => x.id != id));
        if (funcAfter != null) funcAfter(true);
        return true;
      },
      (error) => {
        console.log("Error removing item from cart", error);
        if (funcAfter != null) funcAfter(false);
        return false;
      }
    );
  };

  GetPassengerSeat = (paxId) => {
    var fromBasket = this.state.basketData?.find(
      (i) =>
        (i.passengerExtId == paxId || i.passengerId == paxId) &&
        i.flightId == this.state.selectedFlight.id &&
        this.state.productsData.find(
          (p) => p.id == i.productId && p.type == "Seat"
        )
    )?.variant;
    if (fromBasket != null) return fromBasket;
    else {
      for (let f of this.state.flightData) {
        var pax = f.passengers.find(
          (p) => p.extId == paxId || p.Id == paxId
        )?.requestedSeat;
        if (pax != null) return pax;
      }
    }
  };

  LoadProductsData = () => {
    API.WebServiceJSON(
      "GET",
      "Products/lang=" + this.state.language,
      null,
      (p) => this.setState({ productsData: p }),
      null
    );
  };

  LoadNotification = () => {
    API.WebServiceJSON(
      "GET",
      "notifications/landingpage/" + this.state.language,
      null,
      (data) => this.setState({ generalNotification: data }),
      null
    );
  };
}

export default AppContextProvider;
