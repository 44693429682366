import React, {useState} from 'react';
import LangSelector from './LangSelector';
import { withRouter } from "react-router";
import { FormattedMessage } from "react-intl";
import { AppContext } from "./../AppContext";
import FAQ_Button from "./FAQButton";
import ContactUs_Button from "./ContactUsButton";
import MobileMenu from './MobileMenu';
import Ribbon from './Ribbon';


export default function Header(props) {
	const [FAQDialogOpen, SetFAQDialogOpen] = useState(false);
	const [ContactUsOpen, SetContactUsOpen] = useState(false);

		return (
			<div className="topHeader menu-nav">
				<div className="topLineContainer">
					<div className="topLevelContainer clearfix">
						<div className="row">
							<div onClick={()=>props.OnSelectedSectionChange(1)} className="headerLogo" style={{ cursor: 'pointer' }}>
								<img alt='Smartlynx Airlines logo' className="headerLogo__brandImage " src={process.env.PUBLIC_URL + '/static/smartlynx/smartlynx_Logo.png'} />
							</div>
							<MobileMenu Full openFAQ={()=>SetFAQDialogOpen(true)} openContacts={()=>SetContactUsOpen(true)}   OnSelectedSectionChange={(indx) => props.OnSelectedSectionChange(indx)} logOut={()=>props.logOut()} ></MobileMenu>
							<ul className="menuMisc menuMisc--enableWizard">
								<ContactUs_Button IsModalOpen={ContactUsOpen} SetModalOpen={SetContactUsOpen}></ContactUs_Button>
								<li className="menuMisc__item menuMisc__item--withText " onClick={() => props.OnSelectedSectionChange(6)}>
									<i className="menuMisc__icon icon pass3" />
									<span className="menuMisc__text "><FormattedMessage id="header.MyBooking" /></span>
								</li>
								<FAQ_Button IsModalOpen={FAQDialogOpen} SetModalOpen={SetFAQDialogOpen}></FAQ_Button>
								<LangSelector selectedLanguage={props.selectedLanguage}></LangSelector>
								<li className="menuMisc__item menuMisc__item--withText " onClick={()=>props.logOut()}>
									<i className="menuMisc__icon icon logout" />
									<span className="menuMisc__text "><FormattedMessage id="header.Logout" /></span>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<Ribbon OnSelectedSectionChange={props.OnSelectedSectionChange} activeSection={props.activeSection}></Ribbon>
			</div>
		);
}