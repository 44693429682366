import React, { useState, useContext } from "react";
import { AppContext } from "./AppContext";
import classNames from "classnames/bind";
import { useHistory } from "react-router";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Checkbox,
  FormControlLabel,
  Accordion,
  AccordionSummary,
  RadioGroup,
  Radio,
  AccordionDetails,
} from "@material-ui/core";
import ContactInformationInput from "./Components/ContactInformationInput";
import Notification from "./Components/Notification";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Cart(props) {
  const intl = useIntl();
  const history = useHistory();
  const context = useContext(AppContext);
  const [PoliciesAccepted, SetAccepted] = useState(false);
  const [countryCode, SetCountryCode] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("Paysera");
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(true); // State to manage accordion expansion

  function Complete(e) {
    e.preventDefault();
    if (context.basketData.length > 0) {
      if (PoliciesAccepted) {
        var request = {
          email: e.target["email"]?.value,
          mobile: e.target["mobile"]?.value,
          countryCode: "+" + countryCode,
          language: context.language,
          PaymentOptions: selectedPaymentMethod,
        };

        context.CreateTransactionFromCart(request);
      }
    } else {
      history.push("/mybooking");
    }
  }

  const handleAccordionToggle = () => {
    setIsAccordionExpanded(!isAccordionExpanded);
  };

  // Create the payment options accordion
  const renderPaymentAccordion = () => {
    return (
      <Accordion
        expanded={isAccordionExpanded}
        onChange={handleAccordionToggle}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className="accordion-summary"
          style={{ backgroundColor: "#F3F3F3", fontWeight: 500 }}
        >
          <div className="accordion-text">
            <FormattedMessage
              id="payment.SelectPaymentMethod"
            />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <RadioGroup
            aria-label="payment"
            name="payment"
            value={selectedPaymentMethod} 
            onChange={(e) => setSelectedPaymentMethod(e.target.value)}
          >
            <FormControlLabel
              value="Paysera"
              control={<Radio color="primary" />}
              label={
                <FormattedMessage
                  id="payment.Paysera"
                />
              }
            />
            <FormControlLabel
              value="PayPal"
              control={<Radio color="primary" />}
              label={
                <FormattedMessage
                  id="payment.PayPal"
                />
              }
            />
          </RadioGroup>
        </AccordionDetails>
      </Accordion>
    );
  };

  const priceTotal = context.basketData.reduce(
    (total, item) => total + item.price,
    0
  );

  return (
    <div className="page">
      {context.flightData.map((flight, index) => {
        let passengersList = flight.passengers;
        let titleClass = classNames({
          cartPassengersBySegment__title: true,
          "cartPassengersBySegment__title--direction-out": index === 0,
        });

        let hasProducts = context.basketData.filter(function (el) {
          return el.flightId === flight.id;
        });

        return (
          hasProducts.length > 0 && (
            <div key={index} className="cartSegmentsList">
              <div className="cartPassengersBySegment">
                <Notification location="Cart" />
                <Notification location="Cart" direction={1} />
                <div className={titleClass}>
                  <div>
                    <div className="cartPassengersBySegment__icon icon plane_takeoff" />
                    {index === 0 ? <div>Outbound</div> : <div>Inbound</div>}
                  </div>
                  &nbsp;&nbsp;{flight.depAirportName}, {flight.flightNumber}
                </div>

                <div className="cartPassengerBySegment__cartServicesByGroup">
                  {passengersList.map((passenger, index) => {
                    let hasProducts = context.basketData.filter(function (b) {
                      return b.passengerExtId === passenger.extId;
                    });

                    return (
                      hasProducts.length > 0 && (
                        <div
                          key={index}
                          className="cartPassengerBySegment__cartServicesByGroup"
                        >
                          <div className="listBySegmentPassegnerGroup">
                            <div className="listBySegmentPassegnerGroup__name">
                              {passenger.firstName} {passenger.lastName}
                            </div>

                            <div className="listBySegmentPassegnerGroup__services">
                              {hasProducts.map((passengerProduct, id) => {
                                let product = context.productsData.find(
                                  (b) => b.id === passengerProduct.productId
                                );
                                return (
                                  <div
                                    key={id}
                                    className="itemBySegmentPassegnerGroup"
                                  >
                                    <div className="itemBySegmentPassegnerGroup__item-info">
                                      <div className="itemBySegmentPassegnerGroup__name">
                                        <span>
                                          {
                                            product?.translations?.find(
                                              (item) => item.key === "title"
                                            )?.content
                                          }{" "}
                                          <div
                                            style={{
                                              // display: "block",
                                              maxWidth: "190px",
                                              textOverflow: "ellipsis",
                                              overflow: "hidden",
                                              whiteSpace: "nowrap",
                                            }}
                                          >
                                            {/* Респонсив + подпись */}
                                            {passengerProduct?.variant}
                                          </div>
                                        </span>
                                      </div>
                                      <div className="itemBySegmentPassegnerGroup__price">
                                        <span>
                                          {passengerProduct.price?.toFixed(2)}{" "}
                                          {passengerProduct.currency}
                                        </span>
                                      </div>
                                    </div>
                                    <button
                                      onClick={() =>
                                        context.RemoveFromCartByParams(
                                          passengerProduct.productId,
                                          flight.id,
                                          passenger.extId
                                        )
                                      }
                                      aria-label="Remove"
                                      type="button"
                                      className="itemBySegmentPassegnerGroup__remove-button"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            </div>
          )
        );
      })}
      <div className="page__content">
        <div className="cartTotal">
          <form onSubmit={(e) => Complete(e)}>
            {context.basketData.length > 0 && (
              <div>
                <div
                  style={{ width: "50%", marginLeft: "auto", marginRight: 0 }}
                >
                  {context.basketData.find((x) => x.productCode == "BRB") && (
                    <ContactInformationInput
                      onCountryCodeChanged={(value) => SetCountryCode(value)}
                    ></ContactInformationInput>
                  )}
                </div>

                {/* <div style={{textAlign:'left'}}> 
        <ul>
          <li style={{margin:'20px'}}><input type="radio" name="test" id="cb1" />
            <label for="cb1"><img src="/static/smartlynx/Paysera_logo_2022.png" height="50px" /></label>
          </li>
          <li style={{margin:'20px'}}><input type="radio" name="test" id="cb2" />
            <label for="cb2"><img src="/static/smartlynx/PayPal_logo.png" height="50px" /></label>
          </li>
        </ul>
        </div> */}
                {context.PaymentOptions.includes("PayPal") &&
                  renderPaymentAccordion()}

                <FormControlLabel
                  value="privacy"
                  control={
                    <Checkbox 
                      value={PoliciesAccepted}
                      onChange={(e) => SetAccepted(e.target.checked)}
                      id="privacy"
                      required
                      color="primary"
                    />
                  }
                  label={
                    <div
                      dangerouslySetInnerHTML={{
                        __html: intl.formatMessage({
                          id: "payment.AgreeWithTerms",
                        }),
                      }}
                    />
                  }
                  labelPlacement="end"
                />
              </div>
            )}

            <div className="cartTotal__amount">
              <div className="cartTotal__amountText">Total</div>
              <div className="cartTotal__amountNumber">
                {priceTotal?.toFixed(2)} EUR
              </div>
            </div>
            <button
              type="submit"
              className={classNames({
                cartTotal__button: true,
                "cartTotal__button--disabled":
                  context.basketData.length > 0 && !PoliciesAccepted,
              })}
            >
              {context.basketData.length > 0 ? (
                <FormattedMessage id="payment.pay" />
              ) : (
                <FormattedMessage id="myBooking.ToMyBooking" />
              )}
            </button>
          </form>
        </div>
        <div className="basketError" />
      </div>
    </div>
  );
}
