import React, { useState, useContext } from "react";
import {
  Button,
  DialogTitle,
  DialogContent,
  Dialog,
  DialogActions,
} from "@material-ui/core";
import {
  TextField,
  FormControl,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { AppContext } from "../AppContext";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgress } from "@material-ui/core";
import classes from "../css/CheckInDialog.module.scss";
import ContactInformationInput from "../Components/ContactInformationInput";

export default function CheckInDialog(props) {
  const intl = useIntl();
  const context = useContext(AppContext);
  const [countryCode, SetCountryCode] = useState(null);
  const [CheckinInProgress, SetCheckinInProgress] = useState(false);

  function SubmitCheckinFrom(e) {
    e.preventDefault();
    context.SetDataLoading(true);

    var request = {
      flightId: props.flight.id,
      email: e.target["email"].value,
      mobile: e.target["mobile"].value,
      countryCode: "+" + countryCode,
      newsletter: e.target["offers"].checked,
      language: context.language,
    };

    context.API.WebServiceAction(
      "POST",
      "Checkin",
      request,
      () => props.onSuccess(),
      null
    );
  }

  const dangerousGoods = [
    {
      title: (
        <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.HazardousMaterials" />
      ),
      description: (
        <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.Description" />
      ),
      items: [
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.Explosives" />
          ),
          image: 9,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.FlammableLiquids" />
          ),
          image: 15,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.FlammableSolids" />
          ),
          image: 10,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.FlammableGases" />
          ),
          image: 8,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.RadioactiveSubstances" />
          ),
          image: 14,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.Poisons" />
          ),
          image: 12,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.CorrosiveSubstances" />
          ),
          image: 11,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.Oxidisers" />
          ),
          image: 13,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.HazardousMaterials.DamagedBatteries" />
          ),
          image: 7,
        },
      ],
    },
    {
      title: (
        <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.ProhibitedItems" />
      ),
      description: (
        <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.Description" />
      ),
      items: [
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.Guns" />
          ),
          image: 5,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.SharpObjects" />
          ),
          image: 4,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.StunDevices" />
          ),
          image: 6,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.Tools" />
          ),
          image: 3,
        },
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.ProhibitedItems.BluntInstruments" />
          ),
          image: 2,
        },
      ],
    },
    {
      title: (
        <FormattedMessage id="myBooking.DangerousGoods.SecondaryProhibitedItems.SecondaryProhibitedItems" />
      ),
      description: (
        <FormattedMessage id="myBooking.DangerousGoods.SecondaryProhibitedItems.Description" />
      ),
      items: [
        {
          name: (
            <FormattedMessage id="myBooking.DangerousGoods.SecondaryProhibitedItems.Explosives" />
          ),
          image: 1,
        },
      ],
    },
  ];

  if (props.flight == null) return null;

  const icons = (item) => {
    const iconArr = [];
    for (let i = 0; i < item.length; i++) {
      iconArr.push(
        <li className={item.length === 1 ? classes.line : ""}>
          <div className={classes.itemContainer}>
            <img
              src={`./images/icons/icons - 01-${
                item[i].image < 10 ? `0${item[i].image}` : item[i].image
              }.svg`}
            />
            <h3>{item[i].name}</h3>
          </div>
        </li>
      );
    }
    return iconArr;
  };

  const dangerousGoodsFunc = () => {
    return dangerousGoods.map((good) => {
      return (
        <div className={classes.goodsContainer}>
          <h2 className={classes.goodsCategory}>{good.title}</h2>
          <p>{good.description}</p>
          <ul>{icons(good.items)}</ul>
        </div>
      );
    });
  };

const DocumentCheckBlock= () => {
    return (
    <div className={classes.goodsContainer}>
      <h2 className={classes.goodsCategory}><FormattedMessage id="myBooking.DocumentCheck.Title" /></h2>
      <p></p>
      
      <p><img style={{ width:"32px", marginRight:"10px" }} src={`./images/warning.svg`}></img><FormattedMessage id="myBooking.DocumentCheck.MandatoryForFlight" /></p>
    </div>
    );
};

  return (
    <Dialog
      maxWidth="md"
      disableBackdropClick
      onClose={() => props.onClose()}
      open
    >
      <form onSubmit={(e) => SubmitCheckinFrom(e)}>
        <DialogTitle>
          <FormattedMessage id="myBooking.CheckinToFlight" />{" "}
          {props.flight?.flightNumber}
        </DialogTitle>
        <DialogContent>
          <FormControl>
            <ContactInformationInput
              onCountryCodeChanged={(value) => SetCountryCode(value)}
            ></ContactInformationInput>

            <div className={classes.goods}>
              {props.flight.mandatoryDocumentCheckRequired ? DocumentCheckBlock():""}
              {dangerousGoodsFunc()}
          
            </div>
            <FormControlLabel
              className={classes.policy}
              value="privacy"
              control={<Checkbox id="privacy" required color="primary" />}
              label={
                <div>
                  <FormattedMessage id="myBooking.IreadPrivacyPolicy" />*{" "}
                  <a
                    href={intl.formatMessage({ id: "footer.PrivacyPolicyURL" })}
                    target="_blank"
                  >
                    <FormattedMessage id="sections.ReadMore" />
                  </a>
                </div>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="baggage"
              control={<Checkbox id="baggage" required color="primary" />}
              label={
                <div>
                  <FormattedMessage id="myBooking.IacceptConditions" />*{" "}
                  <a
                    href={intl.formatMessage({ id: "footer.BeforeFlightURL" })}
                    target="_blank"
                  >
                    <FormattedMessage id="sections.ReadMore" />
                  </a>
                </div>
              }
              labelPlacement="end"
            />
            <FormControlLabel
              value="offers"
              control={<Checkbox id="offers" color="primary" />}
              label={<FormattedMessage id="myBooking.IwantNewsletter" />}
              labelPlacement="end"
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          {CheckinInProgress ? (
            <CircularProgress />
          ) : (
            <div>
              <Button type="submit" color="primary">
                <FormattedMessage id="myBooking.ProceedToCheckin" />
              </Button>
              <Button onClick={() => props.onClose()} color="primary">
                <FormattedMessage id="common.Close" />
              </Button>
            </div>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
}
