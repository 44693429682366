import React from 'react';
import { FormattedMessage, useIntl } from "react-intl";

export default function FooterLanding(props) {
	const intl = useIntl();

		return (
            <div className="footer_main">
              <div className="footer">
                <div className="container">
                  <div className='shield'></div>
                  <div className="row cookie-disclaimer">SmartLynx Airlines SIA, Registration nr.: 40003056133, +371 60003089, info@smartlynx.aero, Mazrudas, Marupe municipality, LV-2167, Latvia&nbsp;|&nbsp;
                  <div style={{display:'inline'}}/><a href={intl.formatMessage({ id: 'footer.TermsConditionsURL' })}>{intl.formatMessage({ id: 'footer.TermsConditions' })}</a>&nbsp;|&nbsp;<a href={intl.formatMessage({ id: 'footer.PrivacyPolicyURL' })}>{intl.formatMessage({ id: 'footer.PrivacyPolicy' })}</a></div>
                </div>
              </div>
            </div>
		);	
}