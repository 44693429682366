import React, { useContext } from "react";
import PassengerList from "./Seating/PassengerList";
import BuyItem from "./Components/BuyItem";
import { AppContext } from "./AppContext";
import NotificationBar from "./Components/NotificationBar";
import { FormattedMessage } from "react-intl";
import Banner from "./Components/Banner/Banner";
import Notification from "./Components/Notification";

const Baggage = () => {
  const context = useContext(AppContext);
  const servicesData = context.productsData.filter(function (product) {
    //Check if passenger has available product for any flight
    return (
      context.flightData.some((f) =>
        f.passengers.some(
          (pax) =>
            pax.passengerKey == context.selectedPassenger.passengerKey &&
            pax.availableProducts.some((p) => p.productId == product.id)
        )
      ) &&
      (product.typeId === 2 || product.typeId === 3)
    );
  });

  let notif = GetNotification();

  return (
    <div className="page">
      <div className="page__content page__content--mobileFullWidth">
        <Notification location={"Baggage"} />
        <Notification location={"Baggage"} direction={1} />
        <div>
          <div className="columns columns--fullWidth-992">
            <div className="columns__item">
              <div>
                <PassengerList />
                <Banner location="BaggageLeft" />
              </div>
            </div>
            <div className="columns__item">
              <div>
                <div className="servicesContent">
                  <NotificationBar
                    title={notif?.title}
                    description={notif?.description}
                    icon="success"
                  />
                  <div className="listContent">
                    <div>
                      {servicesData.map((row, index) => (
                        <BuyItem key={index} item={row} />
                      ))}
                    </div>
                    {servicesData?.length == 0 && (
                      <div style={{ textAlign: "center" }}>
                        <i>
                          <FormattedMessage id="sections.ServiceNotAvailable" />
                        </i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  function GetNotification() {
    let notificationMessage = [];
    context.flightData?.forEach((flt) => {
      flt.passengers.forEach((pax) => {
        pax.ssr.split(",").forEach((ssr) => {
          let prod = servicesData.find((p) => p.code == ssr && ssr);
          if (prod != null) {
            notificationMessage.push(
              <div key={pax.extId + ssr}>
                <b>
                  {pax.firstName} {pax.lastName}
                </b>{" "}
                {prod.translations.find((p) => p.key === "title")?.content}{" "}
                {flt.flightNumber}
              </div>
            );
          }
        });
      });
    });
    if (notificationMessage?.length > 0)
      return {
        title: <FormattedMessage id="payment.YouAlreadyFromThisList" />,
        description: notificationMessage,
      };
    else return null;
  }
};
export default Baggage;
