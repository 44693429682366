import React, { useContext, useState } from "react";

import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import LangSelector from "./Components/LangSelector";
import { AppContext } from "./AppContext";
import { IntlProvider, FormattedMessage } from "react-intl";
import FAQ_Button from "./Components/FAQButton";
import ContactUs_Button from "./Components/ContactUsButton";
import MobileMenu from "./Components/MobileMenu";
import FooterLanding from "./Components/FooterLanding";
import "./css/LandingPage.scss";
import "./css/Receipt.scss";
import LandingPageNotification from "./Components/LandingPageNotification";
import logo from "./images/SmartLynx-Logo_PNG_transp.png";

export default function Main(props) {
  const context = useContext(AppContext);
  const history = useHistory();
  const [LoginErrorPopupOpen, setLoginError] = useState(false);
  const [FAQDialogOpen, setFAQDialog] = useState(false);
  const [ContactUsOpen, setContactUs] = useState(false);

  function handleSubmit(event) {
    event.preventDefault();

    context.API.WebServiceText(
      "POST",
      "Login/passenger",
      {
        BookingNr: event.target.bookingNumber.value,
        LastName: event.target.bookingSurname.value,
      },
      (token) => {
        localStorage[context.API.AuthTokenName] = token;
        window.location = window.location.origin + "/seating";
      },
      () => {
        setLoginError(true);
        context.API.logOut();
      }
    );
  }

  React.useEffect(() => {
    if (context.API.AuthToken) history.push("/seating");
  }, []);

  return (
    <IntlProvider
      locale={context.language}
      messages={
        context.translations[context.language] ?? context.translations["en"]
      }
    >
      <div className="LandingPage">
        <div className="paxshopApp__wrapper">
          <LandingPageNotification />
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="topHeader menu-nav">
                  <div className="topLineContainer">
                    <div className="headerLogo" style={{ cursor: "pointer" }}>
                      <img
                        alt="Smartlynx Airlines logo"
                        className="headerLogo__brandImage headerLogo__brandImage--isLoginPage"
                        src={logo}
                      />
                    </div>
                    <MobileMenu
                      openFAQ={() => setFAQDialog(true)}
                      openContacts={() => setContactUs(true)}
                    ></MobileMenu>
                    <ul className="menuMisc menuMisc--enableWizard">
                      <ContactUs_Button
                        IsModalOpen={ContactUsOpen}
                        SetModalOpen={(cond) => setContactUs(cond)}
                      />
                      <FAQ_Button
                        IsModalOpen={FAQDialogOpen}
                        SetModalOpen={(cond) => setFAQDialog(cond)}
                      />
                      <LangSelector></LangSelector>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row page">
              <div className="col-lg-7 col-sm-6 col-xs-12">
                <h1>
                  <FormattedMessage id="Landing.Welcome" />
                </h1>
                <p id="extras1">
                  <FormattedMessage id="Landing.Extras1" />
                </p>
                <p id="extras2">
                  <FormattedMessage id="Landing.Extras2" />
                </p>
              </div>
              <div className="col-lg-5 col-sm-6 col-xs-12">
                <div className="login-header">
                  <FormattedMessage id="Landing.EnterToLogin" />
                </div>
                <div className="login-form">
                  <form onSubmit={handleSubmit}>
                    <div className="form-item">
                      <label htmlFor="bookingNumber">
                        <FormattedMessage id="Landing.BookingNumber" />
                      </label>
                      <input
                        type="text"
                        className="textInput"
                        name="bookingNumber"
                        id="bookingNumber"
                      />
                    </div>
                    <div className="form-item">
                      <label htmlFor="bookingSurname">
                        <FormattedMessage id="Landing.Surname" />
                      </label>
                      <input
                        type="text"
                        className="textInput"
                        name="bookingSurname"
                        id="bookingSurname"
                      />
                      {/* <span className="calendar" /> */}
                    </div>
                    <button type="submit">
                      <FormattedMessage id="Landing.Login" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container">
          <div className="row">
            <div className="col-lg-12 notice-container">
              <div className=" notice">
                <p><FormattedMessage id="Landing.NoteRefunds" /></p>
                <p><FormattedMessage id="Landing.CheckinOpenPolicy" /></p>
                <span className="close" onClick={(e)=>{var el = document.querySelector('.notice'); el.style.display = 'none';}} />
              </div>
            </div>
          </div>
        </div> */}
        <FooterLanding></FooterLanding>

        {/* <div role="presentation" className="MuiDialog-root" style={{ position: 'fixed', zIndex: 1300, right: 0, bottom: 0, top: 0, left: 0 }}>
          <div className="MuiBackdrop-root" aria-hidden="true" style={{ opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }} />
          <div className="MuiDialog-container MuiDialog-scrollPaper" tabIndex={-1} style={{ opacity: 1, transition: 'opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms' }}>
            <div className="MuiPaper-root MuiDialog-paper MuiDialog-paperScrollPaper MuiDialog-paperWidthSm MuiPaper-elevation24 MuiPaper-rounded modal" role="dialog">
              <div className="modal-header">
                Frequently Asked Questions <span className="modal-close" onclick="hideModal()" />
              </div>
              <div className="modal-body">
                <ul>
                  <li>
                    <div className="question">
                      How long before departure can I pre book my services?
              </div>
                    <div className="answer">
                      No earlier than 72 hours and no later than 4hours before departure (no later than 24hours for meals).
              </div>
                  </li>
                  <li>
                    <div className="question">
                      What rules apply to children under 2?
              </div>
                    <div className="answer">
                      Children under 2 do not receive a separate seat, they must sit in the lap of an adult.
              </div>
                  </li>
                  <li>
                    <div className="question">
                      There are several of us travelling on the same booking number but not everyone wants to buy a seat. Is this permitted?
              </div>
                    <div className="answer">
                      All passengers with the same booking number must buy a seat.
              </div>
                  </li>
                  <li>
                    <div className="question">
                      Can I buy seats for several booking numbers at the same time?
              </div>
                    <div className="answer">
                      No, you can't book seats for several booking numbers at the same time.
              </div>
                  </li>
                  <li>
                    <div className="question">
                      If I have a doctor's certificate, can I book a specific seat free of charge?
              </div>
                    <div className="answer">
                      Unfortunately, if you require a specific seat, you must pay for it prior to departure. Passengers with medical requirements should contact their tour operator.
              </div>
                  </li>
                  <li>
                    <div className="question">
                      What happens to my pre booked services if I cancel my trip?
              </div>
                    <div className="answer">
                      If you need to cancel your trip, please call SmartLynx customer support on: +371 60003089, or fill out this form: <a href="#">Click here</a>.
              </div>
                  </li>
                  <li>
                    <div className="question">
                    </div>
                    <div className="answer">
                    </div>
                  </li>
                </ul>
                <h2>
                  General booking rules
          </h2>
                <ul>
                  <li>
                    <div className="answer">
                      No earlier than 72 hours and no later than 4hours before departure (no later than 24hours for meals).
              </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div> */}

        <Dialog open={LoginErrorPopupOpen} onClose={() => setLoginError(false)}>
          <div
            className="pxModal__popup textInfoModalPopup"
            style={{ margin: "0px" }}
          >
            <div className="textInfoModal">
              <div
                className="textInfoModal__closeIcon textInfoModal__closeIcon--type-error"
                onClick={() => setLoginError(false)}
              />
              <div className="textInfoModal__titleBar textInfoModal__titleBar--type-error">
                <div className="textInfoModal__headIcon textInfoModal__headIcon--type-error" />
                <div className="textInfoModal__title">
                  <FormattedMessage id="Landing.LoginFailed" />
                </div>
              </div>
              <div className="textInfoModal__content textInfoModal__content--type-error">
                <h3>
                  <FormattedMessage id="Landing.PleaseCheckCredentials" />
                </h3>{" "}
                <br />
                <FormattedMessage id="Landing.BookingProvided" />
                <br />
                <FormattedMessage id="Landing.NoteFlightNumber" />
              </div>
              <div className="textInfoModal__footer">
                <div
                  className="textInfoModal__closeButton"
                  onClick={() => setLoginError(false)}
                >
                  <FormattedMessage id="common.Close" />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </IntlProvider>
  );
}
