import React, { useRef, useState } from "react";
import { Modal, TextareaAutosize } from "@material-ui/core";
import classes from "../css/CelebrateFormDialog.module.scss";

const CelebrateFormDialog = ({ onCloseHandler, onSubmitHandler }) => {
  const refSign = useRef();
  const [value, setValue] = useState("");
  const [textLimitationError, setTextLimitationError] = useState(false);

  const symbolsInRow = 65;
  const allowedRowsCount = 30;

  const submitHandler = (e) => {
    e.preventDefault();

    const celebrationData = `${value}~=${refSign.current.value}`;

    onSubmitHandler(e, celebrationData);
    onCloseHandler();
  };

  const onChangeHandler = (e) => {
    const inputTextByLines = e.target.value.split("\n");

    const newLines = inputTextByLines.map((line) => {
      if (line.length > symbolsInRow) {
        let newLine = "";
        while (line.length > symbolsInRow) {
          newLine += line.substring(0, symbolsInRow) + "\n";
          line = line.substring(symbolsInRow);
        }
        newLine += line;
        return newLine;
      }
      return line;
    });

    newLines.length >= allowedRowsCount
      ? setTextLimitationError(true)
      : setTextLimitationError(false);
    setValue(newLines.join("\n"));
  };

  return (
    <Modal
      open
      onClose={onCloseHandler}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className={classes.container}>
        <form onSubmit={submitHandler}>
          <textarea
            onBlur={onChangeHandler}
            wrap="hard"
            value={value}
            onChange={onChangeHandler}
            cols="20"
            rows="5"
            placeholder="Your celebration text"
            required
          ></textarea>
          {textLimitationError && (
            <p className={classes.error}>
              {`You are out of the text limitations. Limitations is ${symbolsInRow} symbols
              per row and ${allowedRowsCount} rows`}
              .
            </p>
          )}
          <div className={classes.signature}>
            <label htmlFor="signature">Signature</label>
            <input
              required
              id="signature"
              ref={refSign}
              type="text"
              maxLength="50"
            />
          </div>
          <div className={classes.buttons}>
            <button type="submit" disabled={textLimitationError}>
              Save
            </button>
            <button className={classes.close} onClick={onCloseHandler}>
              Cancel
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default CelebrateFormDialog;
