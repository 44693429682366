import React, { useContext } from 'react';
import { FormattedMessage } from "react-intl";
import { AppContext } from "./../AppContext";

export default function NotificationBar(props) {
    const context = useContext(AppContext);

return (<div>
        {(props.title) &&
            <div className={"seatingMessage seatingMessage--type-" + props.icon}>
                <div className="seatingMessage__icon" />
                <div className="seatingMessage__content">
                    <div className="seatingMessage__title">{props.title}</div>
                    <div className="seatingMessage__message">
                        {props.description}
                    </div>
                </div>
                <div className="seatingMessage__closeButton seatingMessage__closeButton--hide">Ok!</div>
            </div>
        }
    </div>
    );
}