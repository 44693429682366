import { useState, useContext, useEffect } from "react";
import classes from "./LiveChat.module.scss";
import OpenLauncher from "../../images/live-chat/launcher_button.svg";
import CloseLauncher from "../../images/live-chat/clear-button.svg";
import { AppContext } from "../../AppContext";

import ChatWindow from "./Components/ChatWindow";

const LiveChat = () => {
  const [isWidgetOpened, setIsWidgetOpened] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [messages, setMessages] = useState();
  const [sessionClosed, setSessionClosed] = useState(false);
  const [launcherTouched, setLaunchedTouched] = useState(false);

  const context = useContext(AppContext);

  const [sessionHashVar, setSessionHashVar] = useState();
  const [newSessionHashVar, setNewSessionHashVar] = useState();

  useEffect(() => {
    getMessages();
  }, [newSessionHashVar]);

  useEffect(() => {
    if (!sessionId && launcherTouched) {
      getMessages();
      const interval = setInterval(getMessages, 3000);
      return () => clearInterval(interval);
    }
  }, [sessionId, launcherTouched]);

  useEffect(() => {
    if (sessionId) {
      const interval = setInterval(checkChanges, 3000);
      return () => clearInterval(interval);
    }
  }, [sessionId]);

  const getMessages = () => {
    context.API.WebServiceJSON(
      "GET",
      "chat",
      null,
      (z) => {
        if (z.id === 0) {
          setIsOnline(z.online);
        }
        if (sessionId && z.length === 0) {
          setSessionClosed(true);
        }
        setIsOnline(z.online);
        setSessionId(z.id);
        setMessages(z.messages);
        setSessionHashVar(z.messages[z.messages.length - 1].timeStamp);
        setIsWidgetOpened(true);
      },
      null
    );
  };

  const checkChanges = () => {
    context.API.WebServiceJSON(
      "GET",
      `chat/lastActivity/${sessionId}`,
      null,
      (z) => {
        setSessionHashVar(z);
      },
      null
    );
  };

  if (sessionHashVar !== newSessionHashVar)
    setNewSessionHashVar(sessionHashVar);

  return (
    <>
      <div className={classes.widgetContainer}>
        {isWidgetOpened && (
          <ChatWindow
            widgetOpenedHandler={() => setIsWidgetOpened(false)}
            getMessages={getMessages}
            sessionId={sessionId}
            messages={messages}
            isOnline={isOnline}
            sessionClosed={sessionClosed}
            setSessionClosed={setSessionClosed}
          />
        )}
        <button
          onClick={() => {
            setIsWidgetOpened(!isWidgetOpened);
            setLaunchedTouched(true);
          }}
          className={classes.launcher}
        >
          <img
            src={isWidgetOpened ? CloseLauncher : OpenLauncher}
            className={`${
              !isWidgetOpened
                ? classes["open-launcher"]
                : classes["close-launcher"]
            }`}
            alt={`${!isWidgetOpened ? "Close" : "Open"} chat`}
          />
          {/* <span className={classes.badge}>2</span> */}
        </button>
      </div>
    </>
  );
};

export default LiveChat;
