import React, { useContext,useEffect } from 'react';
import PassengerList from './Seating/PassengerList'
import BuyItem from "./Components/BuyItem";
import { AppContext } from "./AppContext";

const { Callback } = require('ecommpay');


export default function EcommPay_Start(props) {
 
  const context = useContext(AppContext);
  const { Payment } = require('ecommpay');


  function ProcessPayment() {
    const urlParams = new URLSearchParams(window.location.search);
    let dataQuery = urlParams.get('data');

    if (dataQuery != null) {
      const e = new Payment(process.env.REACT_APP_EcommpayProjectId, process.env.REACT_APP_EcommpaySecret);
      let data = window.atob(dataQuery).split(',')
      
      // set payment details 
      e.paymentId = data[0];
      e.paymentAmount = parseInt(data[1]);
      e.paymentCurrency = data[2];
      e.merchantReturnUrl =  data[3];
      e.merchantSuccessUrl =  data[3]+"?paymentSuccess=true";
    
      // set another parameters, like success or fail callback URL, customer details, etc.
  
      // get payment URL
      const url = e.getUrl();
      window.location.href = url;
    }
  }

  useEffect(() => {
    ProcessPayment();
  }, []);


  return (
    <div className="page">
      Please wait...
    </div>
  );


};