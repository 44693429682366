import React from "react";
import { AppContext } from "./../AppContext";
import { FormattedMessage } from "react-intl";

const validTitles = ["INF", "ADT", "MRS", "MR", "CHD"];

class PassengerList extends React.Component {
  CurrentPaxChanged = (newIndx) => {
    this.props.OnCurrentPaxChanged(newIndx);
  };
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }

  render() {
    return (
      <div className="pageCloud">
        <div className="pageCloud__title ">
          <div>
            <FormattedMessage id="seating.ListOfPassengers" />
          </div>
        </div>
        <div>
          <div className="passengerSelector">
            {this.context.passengers.map((pax, index) => {
              return (
                <div key={index}>
                  <div
                    onClick={() => this.context.CurrentPaxChanged(index)}
                    className={
                      index == this.context.selectedPassengerIndex
                        ? "passengerSelector__item passengerSelector__item--isCurrent"
                        : "passengerSelector__item "
                    }
                  >
                    <div className="passengerSelector__icon" />
                    <div className="passengerSelector__info">
                      <div className="passengerSelector__name">
                        {pax.firstName} {pax.lastName}
                      </div>

                      <div className="passengerSelector__extraInfo">
                        <div className="passengerSelector__type">
                          {validTitles.includes(pax.type) ? pax.type : ""}
                        </div>

                        {pax.relatedInfant && (
                          <>
                            {" "}
                            <div className="passengerSelector__infant">
                              +
                              <div
                                id="hoverId"
                                className="passengerSelector__infant_hoverText"
                              >
                                {pax.relatedInfant?.firstName}{" "}
                                {pax.relatedInfant?.lastName}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="passengerSelector__seat">
                          {this.context.GetPassengerSeat(pax.extId ?? pax.id)}
                          {/* ExtraSeat */}
                          {pax.extraSeat ? `+${pax.extraSeat}` : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
PassengerList.contextType = AppContext;
export default PassengerList;
