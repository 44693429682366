import React, { useContext } from "react";
import { AppContext } from "../../AppContext";

import { FormattedMessage } from "react-intl";

const SpecialAssistance = ({ onClick, direction }) => {
  const context = useContext(AppContext);

  const ssrRender = () => {
    let specialAssistanceArr = [];
    const specialAssistanceAvailableProducts = context.productsData.filter(
      (product) => product.typeId === 7
    );

    // passengerKey metric is chosen like generic for both flight even that can be changed for now is better solution to display different assistance for both flight sides

    const [selectedPassenger] = context.flightData[direction].passengers.filter(
      (passenger) =>
        passenger.passengerKey === context.selectedPassenger.passengerKey
    );

    const selectedProductCode = selectedPassenger?.ssr?.split(",");

    selectedProductCode?.map((assistance) => {
      specialAssistanceAvailableProducts.find((product) => {
        if (assistance === product.code) {
          specialAssistanceArr.push(product.translations[0].content);
        }
      });
    });

    const ssrList =
      specialAssistanceArr.length !== 0 ? (
        <ul>
          <FormattedMessage id="specialAssistance.AssistanceSelected" />
          {specialAssistanceArr.map((element, i) => (
            <li key={i}>{element}</li>
          ))}
        </ul>
      ) : null;

    return ssrList;
  };

  return (
    <div className="servicesItem">
      <div
        className="servicesItem__image"
        style={{
          backgroundImage: `url(./static/paxshop-2.0/products/specAssistance.png)`,
        }}
      />
      <div className="servicesItem__body">
        <div className="servicesItem__title">
          <FormattedMessage id="specialAssistance.specialAssistance" />
          &nbsp;{context.flightData[direction].flightNumber}&nbsp;
          {context.flightData[direction].depAirportCode}-
          {context.flightData[direction].arrAirportCode}
        </div>
        <div className="servicesItem__info">
          <div className="servicesItem__serviceDescription">
            <FormattedMessage id="specialAssistance.needAssistance" />
          </div>
        </div>
        {ssrRender()}

        <div className="AddingToBasket">
          <div className="AddingToBasket__button  " onClick={(e) => onClick()}>
            <div>
              <span>
                <span>
                  <FormattedMessage id="specialAssistance.Select" />
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpecialAssistance;
