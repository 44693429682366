import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import clsx from "clsx";

export default function LandingPageNotification(props) {
  const context = useContext(AppContext);
  console.log(context);
  return (
    context.generalNotification && (
      <div
        className={clsx(
          "LandingPageNotification",
          context.generalNotification.severity == 0
            ? "NotificationSeverity-Red"
            : "NotificationSeverity-Yellow"
        )}
        dangerouslySetInnerHTML={{ __html: context.generalNotification.text }}
      ></div>
    )
  );
}
