import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "../AppContext";
import { FormattedMessage } from "react-intl";
import {
  Dialog,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  Checkbox,
  DialogTitle,
  DialogActions,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import { MenuItem, Select, FormControl } from "@material-ui/core";
import specAssistance from "../images/specAssistance.png";

export default function SpecialAssistance(props) {
  const context = useContext(AppContext);
  const [isAccepted, setAccepted] = useState(false);
  const [selectedProducts, setProducts] = useState(getInitialSSRStatus);
  const currentPassenger = props.flight?.passengers?.find(
    (pax) => pax.passengerKey === context.selectedPassenger.passengerKey
  )?.extId;

  function ToggleProduct(e, productId) {
    let prods = [...selectedProducts];
    let exist_index = prods.findIndex(
      (i) => i.passengerId === currentPassenger && i.productId === productId
    );

    if (exist_index == -1)
      prods.push({
        passengerId: currentPassenger,
        productId: productId,
        ssr: context.productsData.find((p) => p.id === productId)?.ssr,
      });
    else prods.splice(exist_index, 1);
    setProducts(prods);
    setAccepted(false);
  }

  function acceptProducts() {
    var init = getInitialSSRStatus();
    let newSsr = selectedProducts.filter(
      (s) => !init.some((i) => i.passengerId == s.passengerId && i.ssr == s.ssr)
    );
    let deletedSsr = init.filter(
      (s) =>
        !selectedProducts.some(
          (i) => i.passengerId == s.passengerId && i.ssr == s.ssr
        )
    );

    let req = [];

    newSsr.forEach((x) =>
      req.push({
        paxId: null,
        paxExtId: x.passengerId,
        ssr: x.ssr,
        operation: "add",
      })
    );
    deletedSsr.forEach((x) =>
      req.push({
        paxId: null,
        paxExtId: x.passengerId,
        ssr: x.ssr,
        operation: "remove",
      })
    );
    if (req.length > 0)
      context.API.WebServiceAction(
        "PUT",
        "reservation/setssr",
        req,
        () => props.handleUpdated(),
        null
      );
  }

  function getInitialSSRStatus() {
    let result = [];
    let allSpecProducts = context.productsData.filter(
      (p) => p.typeId === 7 && p.ssr != ""
    );

    props.flight.passengers.forEach((pax) => {
      let pax_ssr = pax.ssr.split(",");
      pax_ssr.forEach((ssr) => {
        let prodFound = allSpecProducts.find((p) => p.ssr == ssr);
        if (prodFound != null) {
          result.push({
            passengerId: pax.extId,
            productId: prodFound.id,
            ssr: prodFound.ssr,
          });
        }
      });
    });
    return result;
  }

  const specialAssistanceProducts = context.productsData.filter(function (
    product
  ) {
    return (
      props.flight?.passengers?.some(
        (pax) =>
          pax.extId == currentPassenger &&
          pax.availableProducts.some((p) => p.productId == product.id)
      ) && product.typeId === 7
    );
  });

  function ItemSelected(productId) {
    return (
      selectedProducts.findIndex(
        (i) => i.passengerId === currentPassenger && i.productId === productId
      ) != -1
    );
  }

  return (
    <Dialog
      open
      onClose={() => props.handleClose()}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle id="simple-dialog-title">
        <FormattedMessage id="specialAssistance.selectionDialogHeader" />
      </DialogTitle>
      <div
        className="pxModal__closeButton"
        onClick={() => props.handleClose()}
      />
      <DialogTitle style={{ textAlign: "center" }}>
        {context.selectedPassenger.firstName}{" "}
        {context.selectedPassenger.lastName}
        {/* <Select
        style={{ marginLeft: "20px", marginRight: "20px" }}
        onChange={(e) => setCurrentPassenger(e.target.value)}
        autoWidth={true}
        variant="outlined"
        value={currentPassenger}
      >
        {props.flight?.passengers.map((item, index) => (
          <MenuItem key={index} value={item.extId ?? item.id}>
            {item.firstName} {item.lastName}
          </MenuItem>
        ))}
      </Select> */}
      </DialogTitle>

      <div>
        <List component="nav">
          {specialAssistanceProducts?.map((row, index) => {
            return (
              <ListItem
                selected={ItemSelected(row.id)}
                button
                key={index}
                onClick={(event) => ToggleProduct(event, row.id)}
              >
                <ListItemAvatar>
                  <div>
                    {row.imageId != null && (
                      <img
                        alt="Special assistance icon"
                        style={{ height: "40px" }}
                        src={
                          process.env.REACT_APP_WS_URL + "/Files/" + row.imageId
                        }
                      ></img>
                    )}
                  </div>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    row.translations?.find((item) => item.key === "title")
                      ?.content
                  }
                  secondary={
                    row.translations?.find((item) => item.key === "descr")
                      ?.content
                  }
                />
                <ListItemSecondaryAction>
                  <Checkbox
                    edge="end"
                    onChange={(event) => ToggleProduct(event, row.id)}
                    checked={ItemSelected(row.id)}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>

        <FormControlLabel
          style={{ marginLeft: "25px" }}
          value="offers"
          control={
            <Checkbox
              checked={isAccepted}
              onChange={(event) => setAccepted(!isAccepted)}
              id="offers"
              color="primary"
            />
          }
          label={<FormattedMessage id="specialAssistance.confirm" />}
          labelPlacement="end"
        />
      </div>
      <DialogActions>
        <Button onClick={() => props.handleClose()} color="primary">
          <FormattedMessage id="common.Close" />
        </Button>
        <Button
          disabled={!isAccepted}
          onClick={() => {
            acceptProducts();
          }}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="common.Next" />
        </Button>
      </DialogActions>
    </Dialog>
  );
}

// const specialAssistanceProducts = context.productsData.filter(function (product) {
//   return context.flightData.some(f => f.passengers.some(pax => pax.passengerKey == context.selectedPassenger.passengerKey && pax.availableProducts.some(p => p.productId == product.id))) && product.typeId === 7 });
