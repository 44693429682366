import React, { useContext } from "react";
import { AppContext } from "../AppContext";
import clsx from "clsx";

const Notification = ({ location, direction = 0 }) => {
  const context = useContext(AppContext);

  const locations = {
    1: "Seating",
    2: "Baggage",
    3: "Meals",
    4: "Services",
    5: "Cart",
    6: "MyBooking",
  };

  if (!context.flightData[direction]) return null;

  let filter;

  // if (location === locations[1]) {
  //   console.log("location seating");
  // } else {
  filter = context.flightData[
    location === locations[1] ? context.selectedFlightIndex : direction
  ].notifications
    .filter((el) => locations[el.location] === location)
    .slice(-1);

  return (
    <>
      {filter?.map((notification, i) => {
        return (
          notification.translations[context.language] && (
            <div
              style={{ borderRadius: "4px", marginBottom: "2px" }}
              key={i}
              className={clsx(
                "LandingPageNotification",
                notification.severity == 0
                  ? "NotificationSeverity-Red"
                  : "NotificationSeverity-Yellow"
              )}
              dangerouslySetInnerHTML={{
                __html: notification.translations[context.language],
              }}
            ></div>
          )
        );
      })}
    </>
  );
};

export default Notification;
