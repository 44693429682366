import React, { useState, useContext } from "react";
import { AppContext } from "./../AppContext";
import Seat_Unavailable from "./../images/seat_unavailable.png";
import { FormattedMessage } from "react-intl";
import clsx from "clsx";
import NotAllowedAddInCartDialog from "../Dialogs/NotAllowedAddInCartDialog";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
const pm = require("picomatch");

export default function SeatPlan(props) {
  const context = useContext(AppContext);
  const [inProcess, setinProcess] = useState(false);
  const [unpaidCartDialogOpen, setUnpaidCartDialogOpen] = useState(false);

  function ItemBought(itemId, flightId, variant) {
    let itemBought = context.basketData.find(
      (b) =>
        b.productId == itemId &&
        b.flightId == flightId &&
        b.variant == variant &&
        context.flightData
          .find((f) => f.id == flightId)
          .passengers.find(
            (p) =>
              p.passengerKey == context.selectedPassenger.passengerKey &&
              b.passengerExtId == p.extId
          )
    );
    return itemBought;
  }

  function ItemBoughtByAnotherPassenger(itemId, flightId, variant) {
    return context.basketData.some(
      (b) =>
        b.productId == itemId &&
        b.flightId == flightId &&
        b.variant == variant &&
        (b.passengerExtId !== context.selectedPassenger.extId ||
          b.passengerId !== context.selectedPassenger.id)
    );
  }

  function AnotherSeatBought(flightId) {
    let itemBought = context.basketData.filter(
      (b) =>
        b.flightId == flightId &&
        context.selectedFlight.seating.availableSeats.find(
          (s) => s.productId == b.productId
        ) &&
        context.flightData
          .find((f) => f.id == flightId)
          .passengers.find(
            (p) =>
              p.passengerKey == context.selectedPassenger.passengerKey &&
              b.passengerExtId == p.extId
          )
    );
    return itemBought;
  }

  function clickSeat(variant) {
    if (inProcess) return; //wait for previous ws response

    if (context?.selectedPassenger?.checkedIn) return;

    if (context.transactions?.some((transaction) => !transaction?.paid)) {
      setUnpaidCartDialogOpen(true);
      return null;
    }

    let flightId = context.selectedFlight?.id;

    let exist = context.selectedFlight?.seating.availableSeats?.find(
      (s) => s.seat === variant
    );
    if (exist == null || !exist) return;
    let prod = context.productsData.find(
      (p) => p.id == exist.productId && p.type == "Seat"
    );

    //check if product available for passenger
    if (
      !context.selectedPassenger?.availableProducts.find(
        (a) => a.productId === prod?.id
      )
    )
      return;

    if (ItemBoughtByAnotherPassenger(prod?.id, flightId, variant)) {
      //switch to next passenger and to nothing - JIRA CHKN-59
      context.CurrentPaxChanged(
        ++context.selectedPassengerIndex % context.passengers.length
      );
      return;
    }

    let passengerExtId = context.selectedPassenger?.extId;

    //is in cart? then toggle
    if (ItemBought(prod?.id, flightId, variant)) {
      context.RemoveFromCartByParams(prod?.id, flightId, passengerExtId);
      return;
    }

    //is another variant(s) in cart? remove it!
    let seats = AnotherSeatBought(flightId);
    if (seats) {
      seats.forEach((seat) => {
        context.RemoveFromCartByParams(
          seat.productId,
          flightId,
          passengerExtId
        );
      });
    }

    setinProcess(true);
    context.AddToCart(prod?.id, passengerExtId, flightId, variant, (result) =>
      setinProcess(false)
    );
    context.CurrentPaxChanged(
      ++context.selectedPassengerIndex % context.passengers.length
    );
  }

  async function clickSeat(variant) {
    if (inProcess) return; // wait for previous ws response

    if (context?.selectedPassenger?.checkedIn) return;

    if (context.transactions?.some((transaction) => !transaction?.paid)) {
      setUnpaidCartDialogOpen(true);
      return null;
    }

    let flightId = context.selectedFlight?.id;

    let exist = context.selectedFlight?.seating.availableSeats?.find(
      (s) => s.seat === variant
    );
    if (exist == null || !exist) return;
    let prod = context.productsData.find(
      (p) => p.id == exist.productId && p.type == "Seat"
    );

    // check if product available for passenger
    if (
      !context.selectedPassenger?.availableProducts.find(
        (a) => a.productId === prod?.id
      )
    )
      return;

    if (ItemBoughtByAnotherPassenger(prod?.id, flightId, variant)) {
      // switch to next passenger and do nothing - JIRA CHKN-59
      context.CurrentPaxChanged(
        ++context.selectedPassengerIndex % context.passengers.length
      );
      return;
    }

    let passengerExtId = context.selectedPassenger?.extId;

    try {
      setinProcess(true);

      // Remove current seat if it is in the cart
      if (ItemBought(prod?.id, flightId, variant)) {
        await removeFromCartByParamsAsync(prod?.id, flightId, passengerExtId);

        return;
      }

      // Remove other seats in the cart
      let seats = AnotherSeatBought(flightId);
      if (seats) {
        for (let seat of seats) {
          await removeFromCartByParamsAsync(
            seat.productId,
            flightId,
            passengerExtId
          );
        }
      }

      // Add new seat to the cart
      await addToCartAsync(prod?.id, passengerExtId, flightId, variant);

      context.CurrentPaxChanged(
        ++context.selectedPassengerIndex % context.passengers.length
      );
    } catch (error) {
      console.error("Error during seat selection process:", error);
    } finally {
      setinProcess(false);
    }
  }

  async function addToCartAsync(productId, passengerExtId, flightId, variant) {
    return new Promise((resolve, reject) => {
      context.AddToCart(
        productId,
        passengerExtId,
        flightId,
        variant,
        (result) => {
          if (result) resolve();
          else reject(new Error("Failed to add to cart"));
        }
      );
    });
  }

  async function removeFromCartByParamsAsync(
    productId,
    flightId,
    passengerExtId
  ) {
    return new Promise((resolve, reject) => {
      context.RemoveFromCartByParams(
        productId,
        flightId,
        passengerExtId,
        (result) => {
          if (result) resolve();
          else reject(new Error("Failed to remove from cart"));
        }
      );
    });
  }

  function getSeatImage(variant) {
    if (context?.selectedPassenger?.checkedIn) return Seat_Unavailable;

    let exist = context.selectedFlight?.seating.availableSeats?.find(
      (s) => s.seat === variant
    );
    if (exist == null || !exist) return Seat_Unavailable;
    if (
      !context.selectedPassenger?.availableProducts.find(
        (s) => s.productId == exist.productId
      )
    )
      return Seat_Unavailable;
    //If exist
    let prod = context.productsData.find(
      (p) => p.id == exist.productId && p.type == "Seat"
    );
    return process.env.REACT_APP_WS_URL + "/Files/" + prod?.imageId;
  }

  function drawHowerWindow(
    passengerProd,
    SalesEnabled,
    seatAllocatedFor,
    seatSelectedFor
  ) {
    if (seatAllocatedFor)
      return (
        <div>
          <div className="psw-seatInfo__properties">
            <FormattedMessage id="seating.AllocatedFor" />
          </div>
          <div className="psw-seatInfo-description">
            <b>
              {seatAllocatedFor.firstName} {seatAllocatedFor.lastName}
            </b>
          </div>
        </div>
      );
    else if (passengerProd && SalesEnabled)
      return (
        <div>
          <div className="psw-seatInfo-price">
            <span>
              {passengerProd?.price?.toFixed(2)} {passengerProd?.currency}
            </span>
          </div>
          {(seatSelectedFor && (
            <div>
              <div className="psw-seatInfo__properties">
                <FormattedMessage id="seating.SelectedFor" />
              </div>
              <div className="psw-seatInfo-description">
                <b>
                  {seatSelectedFor.firstName} {seatSelectedFor.lastName}
                </b>
              </div>
            </div>
          )) || (
            <div className="psw-seatInfo-description">
              <FormattedMessage id="seating.ClickToAddSeat" />
            </div>
          )}
        </div>
      );
    else
      return (
        <div className="psw-seatInfo-description">
          <FormattedMessage id="seating.SeatUnavailable" />
        </div>
      );
  }

  if (context?.selectedPassenger == null) return null;
  const seatingPlan = context.selectedFlight?.seating;
  var _left = seatingPlan?.rowConfig?.length > 8 ? 47 : 100;

  let _top = 340;
  const _width = 24;
  const _height = 24;
  const _margin = 24;
  const _spacing = 38;
  const _center = 31;
  let data = Array();

  const SalesEnabled = !context?.selectedPassenger?.checkedIn;

  if (seatingPlan)
    for (let row = 1; row <= seatingPlan.rows; row++) {
      if (seatingPlan.rowsToSkip.indexOf(row) != -1) {
        continue;
      }

      let left = _left;

      for (let sPos = 0; sPos < seatingPlan.rowConfig.length; sPos++) {
        const seat = seatingPlan.rowConfig.charAt(sPos);

        let seatAllocatedExtra = context.selectedFlight?.passengers.find(
          (p) => {
            return p.extraSeat == row + seat;
          }
        );

        let seatAllocatedFor = context.selectedFlight?.passengers.find((p) => {
          return p.requestedSeat == row + seat;
        });
        let basketSeat = context.basketData.find(
          (p) =>
            p.variant == row + seat && p.flightId == context.selectedFlight.id
        );
        let seatSelectedFor =
          basketSeat == null
            ? null
            : context.selectedFlight?.passengers.find(
                (p) =>
                  (p.id != null && p.id == basketSeat.passengerId) ||
                  p.extId == basketSeat.passengerExtId
              );
        let isSeatBusy =
          !context.selectedFlight?.seating.availableSeats?.find(
            (s) => s.seat === row + seat
          ) && !seatAllocatedFor;
        let aseat = context.selectedFlight?.seating.availableSeats.find(
          (p) => p.seat == row + seat
        );
        let prod = context.productsData.find((p) => p.id == aseat?.productId);

        //product depending on filter
        let passengerProd = context.selectedPassenger?.availableProducts.find(
          (a) =>
            (a.variantFilter + "")
              .split(",")
              .some((x) => pm.matchBase(row + seat, x)) &&
            a.productId == prod?.id
        );

        if (passengerProd == null) {
          passengerProd = context.selectedPassenger?.availableProducts.find(
            (a) => a.variantFilter == null && a.productId == prod?.id
          );
        }

        if (seat == "-") {
          left += _center;
        } else {
          data.push(
            <>
              <div
                key={row + seat}
                className={"psw-seatBlock psw-seatBlock--size-small"}
                style={{
                  height: _width + "px",
                  width: _height + "px",
                  position: "absolute",
                  backgroundSize: "contain",
                  top: _top + "px",
                  left: left + "px",
                }}
              >
                <div
                  className={clsx(
                    "psw-seatBlock__seat",
                    seatSelectedFor && "psw-seatBlock__seat--type-busy",
                    seatAllocatedFor && "psw-seatBlock__seat--type-bought",
                    seatAllocatedExtra && "psw-seatBlock__seat--type-bought"
                  )}
                  onClick={() => clickSeat(row + seat)}
                >
                  <div
                    className="psw-seatBlock__seatImage"
                    style={{
                      backgroundImage:
                        'url("' + getSeatImage(row + seat) + '")',
                    }}
                  />
                </div>
                <div
                  className={clsx(
                    "psw-seatBlock__info psw-seatInfo",
                    (seatSelectedFor || seatAllocatedFor) && "selected",
                    passengerProd && SalesEnabled && !isSeatBusy && "available"
                  )}
                  style={{
                    position: "absolute",
                    marginBottom: "calc(100% - 22px)",
                    right: "auto",
                  }}
                >
                  <div className="psw-seatInfo-title">
                    <FormattedMessage id="seating.Seat" /> {row + seat}
                  </div>
                  {drawHowerWindow(
                    passengerProd,
                    SalesEnabled,
                    seatAllocatedFor,
                    seatSelectedFor
                  )}
                  <div className="psw-seatInfo__properties">
                    {
                      prod?.translations?.find((item) => item.key == "title")
                        ?.content
                    }
                  </div>
                </div>
              </div>
            </>
          );
          left += _margin;
        }
      }
      _top += _spacing;
    }

  return (
    <>
      {unpaidCartDialogOpen && (
        <NotAllowedAddInCartDialog
          closeModal={() => setUnpaidCartDialogOpen(false)}
        />
      )}
      <div className="psw-app">
        <div>
          <div className="psw-aircraft">
            <div className="psw-backdrop">
              <img
                alt="Seat"
                src={
                  process.env.REACT_APP_WS_URL +
                  "/Files/" +
                  context.selectedFlight?.seating?.imageId
                }
                className="psw-backdrop__img"
              />
            </div>
            <div className="psw-seatmap">
              <div>{data}</div>
            </div>
          </div>
        </div>
        <div className={`psw-loader ${inProcess && "process"}`}>
          <CircularProgress />
        </div>
      </div>
    </>
  );
}
