import React, { useState, useContext } from 'react';
import { FormattedMessage } from "react-intl";
import clsx from 'clsx';
import { AppContext } from "../AppContext";
export default function MobileMenu(props) {

	const [IsMobileMenuActive, SetMobileMenuActive] = useState(false);
	const context = useContext(AppContext);
	const priceTotal = context.basketData.reduce((total, item) => total + item?.price, 0);
	return (
		<div>

			<div className="mobileHeaderMenu">
				{props.Full &&
					<div className="mobileHeaderMenu__menuItem mobileHeaderMenu__cart " onClick={() => { props.OnSelectedSectionChange(5); }}>
						<i className="icon cartmobile" />

						{ context.basketData.length>0 && 
						<div className="mobileHeaderMenu__cartItemsCount mobileHeaderMenu__cartItemsCount--hidden">{priceTotal?.toFixed(2)}</div>
						}
					</div>
				}
				<div className="mobileHeaderMenu__menuItem mobileHeaderMenu__menu" onClick={() => SetMobileMenuActive(true)}>
					<i className="icon menu" />
				</div>
			</div>
			<div className="mobileMenu">

				<div className={clsx("mobileMenu__overlay", IsMobileMenuActive && "mobileMenu__overlay--active")} />
				<div className={clsx("mobileMenu__menuBox", IsMobileMenuActive && "mobileMenu__menuBox--active")} >
					<div className="mobileMenuContent">
						<div className="mobileMenuContent__closeButton" onClick={() => SetMobileMenuActive(false)}>
							<span className="icon close-light" />
						</div>
						{props.Full &&
						<ul className="mobileMenuContent__menuBlock">
							<li className="mobileMenuContent__menuItem  mobileMenuContent__menuItem--current" onClick={() => { props.OnSelectedSectionChange(1); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon chair" />
								</div>
								<div className="mobileMenuContent__menuItemMarker">
									<div className="mobileMenuContent__wizardIcon mobileMenuContent__wizardIcon--type-NONE" />
								</div>
								<div className="mobileMenuContent__menuItemCaption">1. <span><FormattedMessage id="sections.СhooseSeating" /></span>
								</div>
							</li>
							<li className="mobileMenuContent__menuItem  " onClick={() => { props.OnSelectedSectionChange(2); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon baggage" />
								</div>
								<div className="mobileMenuContent__menuItemMarker">
									<div className="mobileMenuContent__wizardIcon mobileMenuContent__wizardIcon--type-NONE" />
								</div>
								<div className="mobileMenuContent__menuItemCaption">2. <span><FormattedMessage id="sections.ExtraLuggage" /></span>
								</div>
							</li>
							<li className="mobileMenuContent__menuItem  " onClick={() => { props.OnSelectedSectionChange(3); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon flight_extras" />
								</div>
								<div className="mobileMenuContent__menuItemMarker">
									<div className="mobileMenuContent__wizardIcon mobileMenuContent__wizardIcon--type-NONE" />
								</div>
								<div className="mobileMenuContent__menuItemCaption">3. <span><FormattedMessage id="sections.Meals" /></span>
								</div>
							</li>
							<li className="mobileMenuContent__menuItem  " onClick={() => { props.OnSelectedSectionChange(4); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon airportServices" />
								</div>
								<div className="mobileMenuContent__menuItemMarker">
									<div className="mobileMenuContent__wizardIcon mobileMenuContent__wizardIcon--type-NONE" />
								</div>
								<div className="mobileMenuContent__menuItemCaption">4. <span><FormattedMessage id="sections.Services" /></span>
								</div>
							</li>
							<li className="mobileMenuContent__menuItem mobileMenuContent__menuItem--disabled " onClick={() => { props.OnSelectedSectionChange(5); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon cart" />
								</div>
								<div className="mobileMenuContent__menuItemMarker">
									<div className="mobileMenuContent__wizardIcon mobileMenuContent__wizardIcon--type-NONE" />
								</div>
								<div className="mobileMenuContent__menuItemCaption">5. <span><FormattedMessage id="sections.Complete" /></span>
								</div>
							</li>
						</ul>
						}
						<ul className="mobileMenuContent__menuBlock">
							<li className="mobileMenuContent__menuItem" onClick={() => { props.openContacts(); SetMobileMenuActive(false) }} >
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon contacts" />
								</div>
								<div className="mobileMenuContent__menuItemCaption"><FormattedMessage id="header.ContactUs" /></div>
							</li>
							{props.Full &&
							<li className="mobileMenuContent__menuItem" onClick={() => { props.OnSelectedSectionChange(6); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon pass3" />
								</div>
								<div className="mobileMenuContent__menuItemCaption"><FormattedMessage id="header.MyBooking" /></div>
							</li>
}
							<li className="mobileMenuContent__menuItem" onClick={() => { props.openFAQ(); SetMobileMenuActive(false) }}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon help-filled" />
								</div>
								<div className="mobileMenuContent__menuItemCaption"><FormattedMessage id="header.FAQ" /></div>
							</li>
							{props.Full &&
							<li className="mobileMenuContent__menuItem" onClick={() => props.logOut()}>
								<div className="mobileMenuContent__menuItemIcon">
									<span className="icon logout" />
								</div>
								<div className="mobileMenuContent__menuItemCaption"><FormattedMessage id="header.Logout" /></div>
							</li>
}
						</ul>
						<ul className="mobileMenuContent__menuBlock">

							{
								context.languagesAvailable.map((lang, indx) =>
									(
										<li key={indx} className="mobileMenuContent__menuItem" onClick={() => { context.LangChange(lang.code); SetMobileMenuActive(false); }}>
											<div className={clsx("mobileMenuContent__menuItemIcon mobileMenuContent__langIcon", lang.code === context.language && "mobileMenuContent__langIcon--current")}>
												<span className="icon checkmark" />
											</div>
											<div className="mobileMenuContent__menuItemCaption">{lang.title}</div>
										</li>
									))
							}

						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
