import React, { useState, useEffect, useContext } from 'react';
import {IntlProvider, FormattedMessage } from "react-intl";
import {AppContext} from "./AppContext";
import { format } from "date-fns";

function BoardingPass(props) {
  const context = useContext(AppContext);
  const [data, setData] = useState(null);
  const params = new URLSearchParams(window.location.search);
  const ReadData = () => {

    //passenger option
    if (params.get('t') != null) {
      context.API.WebServiceJSON('GET', 'transactions/' + params.get('t') + '/receipt/'+context.language, null, (data) => setData(data), null);
    }
    else if (params.get('tb') != null) {
      const requestOptions = { method: 'GET', headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + params.get('s') } };
      fetch(process.env.REACT_APP_WS_URL + '/transactions/' + params.get('tb') + '/receipt/backend', requestOptions).then(response => { if (response.ok) { return response.json() } }).then(data => setData(data));

    }
  }

  useEffect(ReadData, []);
  if (data == null) { return null } else
    return (
      <IntlProvider locale={data?.language??context.language} messages={context.translations[data?.language??context.language]??context.translations['en']}>
      <div className="receipt">

      <div style={{textAlign:'right'}}>
      <img alt='Smartlynx Airlines logo'
            className="headerLogo__brandImage headerLogo__brandImage--isLoginPage"
            src="/static/smartlynx/smartlynx_Logo.png" />
      </div>
      <div className="invoiceTitle"><FormattedMessage id="receipt.invoice" /></div>

      <div className='infoRow'>
         <div className='infoKey'><FormattedMessage id="receipt.invoiceNumber" /></div> <div className='infoValue'>{data.orderId}</div>
      </div>

      <div className='infoRow'>
         <div className='infoKey'><FormattedMessage id="Landing.BookingNumber" /></div> <div className='infoValue'>{data.bookingNr}</div>
      </div>

      <div className='infoRow'>
          <div className='infoKey'><FormattedMessage id="receipt.transactionDate" /></div> <div className='infoValue'>{format(new Date(data.transactionCreated), 'dd.MM.yyyy HH:mm')}</div>
      </div>
      {/* <div className='infoRow'>
           <div className='infoKey'><FormattedMessage id="receipt.receiptCreated" /></div> <div className='infoValue'>{format(new Date(data.receiptDate), 'dd.MM.yyyy HH:mm')}</div>
      </div> */}
         
      <div className='infoRow' style={{marginTop:'25px'}}>
           <div className='infoKey'><FormattedMessage id="receipt.passengers" />: </div>   
      </div>

      {
      data.passengers.map((item, index) =>
      (
        <div key={index}>
          {item.firstName} {item.lastName}
       </div>
      )
      )
      } 

      <div className='infoRow' style={{marginTop:'25px'}}>
           <div className='infoKey'><FormattedMessage id="receipt.purchase" /></div>   
      </div>

      <table className='purchaseTable'>   
        <thead>
          <tr>
            <th><FormattedMessage id="receipt.nameSurname" /></th>
            <th><FormattedMessage id="receipt.flight" /></th>
            <th><FormattedMessage id="receipt.serviceCode" /></th>
            <th><FormattedMessage id="receipt.product" /></th>
            <th><FormattedMessage id="receipt.price" /></th>
            <th><FormattedMessage id="receipt.quantity" /></th>
            <th><FormattedMessage id="receipt.total" /></th>
            <th><FormattedMessage id="receipt.currency" /></th>                   
          </tr>
        </thead>
        <tbody>
          {

          data.products.map((row, index) =>
          (
             <tr key={index} >
              <td>{row.name}</td>
              <td>{row.flightNumber}</td>
              <td>{row.code}</td>
              <td>{row.product}</td>
              <td>{(row.price/(row.vat/100+1)).toFixed(2)}</td>
              <td>1</td>
              <td>{(row.price/(row.vat/100+1)).toFixed(2)}</td>
              <td>{row.currency}</td>   
             </tr>
          )
          )
          }

        </tbody>
      </table>



{
      Object.keys(data.vat).map((key, index) =>
      (
     
      
        <div key={index} className='vatRow'>
          <div className='infoKey'><FormattedMessage id="receipt.amountSubjectToVAT" /> {key} %: </div> <div className='infoValue'>{(data.vat[key] - (data.vat[key] -data.vat[key] / (key/100+1) )).toFixed(2)} {data.currency}</div>
       </div>
      )
      )
      } 

{
      Object.keys(data.vat).filter(p=>p!="0.00").map((key, index) =>     
      (
        <div key={index}  className='vatRow'>
          <div className='infoKey'><FormattedMessage id="receipt.VAT" /> {key} %: </div> <div className='infoValue'>{ (data.vat[key] -data.vat[key] / (key/100+1) ).toFixed(2)} {data.currency}</div>
       </div>
       
      )
      )
      } 

        <div className='vatRow'>
          <div className='infoKey'><FormattedMessage id="receipt.totalPrice" />: </div> <div className='infoValue'>{data.priceTotal.toFixed(2)} {data.currency}</div>
       </div>


      <div className='vatInfoRow'><FormattedMessage id="receipt.VAT0Rules" /></div>
      
      <div style={{marginTop:'50px', textDecoration:'underline'}}><FormattedMessage id="receipt.serviceProvided" /></div>

      <div>SIA Smartlynx Airlines</div>
      <div>Mazrūdas, Mārupes novads</div>
      <div>Latvia, LV-2167</div>
      <div>Reg. Nr. 40003056133</div>
      <div>PVN/VAT Nr. LV40003056133</div>



<div className='footerInfo'><FormattedMessage id="receipt.receiptIsInformative" /></div>

      </div>
      </IntlProvider>
    )
}
export default BoardingPass;